import React, { useState } from 'react';

const DocumentoElement = ({ onClickGetLinkDocumento, nomeFile, urlFile, isPdf, isImg, privato}) => {

  const [spinner, setSpinner] = useState(false);

const onClickSPINNERGetLinkDocumento = (urlFile) => {
  onClickGetLinkDocumento(urlFile)
  setSpinner(true)
  setInterval(function() {
    setSpinner(false)
}, 3000);
}


  return (
    <div onClick={() => spinner ? null : onClickSPINNERGetLinkDocumento(urlFile)} class="file zoom">
      <div class="no-underline w-inline-block">
        <div class="file-wrap zoom">
          <div class="icona-doc">
            <div class={`condiviso-file zoom ${privato ? 'privato' : 'condiviso'}`}></div>
            {spinner ?
            <img src={'/assets/media/spinner.gif'} loading="lazy" alt="" class="preview-file zoom" />
            :
            privato ?
            <img src={isPdf ? '/assets/media/pdf-privato.jpg' : isImg ? '/assets/media/img-privato.jpg' : '/assets/media/file-pubblico.png'} loading="lazy" alt="" class="preview-file zoom" />
            :
            <img src={isPdf ? '/assets/media/pdf-pubblico.jpg' : isImg ? '/assets/media/img-pubblico.jpg' : '/assets/media/file-pubblico.png'} loading="lazy" alt="" class="preview-file zoom" />
            }
          </div>
          <div>
            <p><b>{nomeFile}</b></p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DocumentoElement;