import axios from 'axios';
import { GET_LOGO, GET_IMPOSTAZIONI, MODIFCA_IMPOSTAZIONI, IMPOSTAZIONI_ERROR } from './types';
import { setAlert } from './alert';

export const getLogo = () => async dispatch => {
    try{
        const res = await axios.get('/api/impostazione/getLogo');
        dispatch({
            type: GET_LOGO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: IMPOSTAZIONI_ERROR
        });
    }
}


export const getImpostazioni = () => async dispatch => {
    try{
        const res = await axios.get('/api/impostazione/getImpostazioni');
        dispatch({
            type: GET_IMPOSTAZIONI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: IMPOSTAZIONI_ERROR
        });
    }
}


export const modificaImpostazioni = ({ logo, emailNotifiche }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ logo, emailNotifiche });
    try{
        const res = await axios.post('/api/impostazione/modificaImpostazioni', body, config);
        dispatch({
            type: MODIFCA_IMPOSTAZIONI,
            payload: res.data
        });
        dispatch(setAlert("impoostazioni modificate con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: IMPOSTAZIONI_ERROR
        });
    }
}