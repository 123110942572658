import { GET_CONDOMINIO, GET_CONDOMINI, CREA_CONDOMINIO, ATTIVA_DISATTIVA_CONDOMINIO, MODIFICA_CONDOMINIO, CHECK_LINK_REGISTRAZIONE_CONDOMINIO, GET_CONDOMINI_CONSIGLIERI, CONDOMINIO_ERROR } from '../actions/types';

const initialState = {
    condomini: [],
    condominio: null,
    consiglieri: [],
    loading: true
}

export default function auth (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_CONDOMINIO:
        case ATTIVA_DISATTIVA_CONDOMINIO:
        case MODIFICA_CONDOMINIO:
        case CHECK_LINK_REGISTRAZIONE_CONDOMINIO:
            return {
                ...state,
                condominio: payload,
                loading: false
            };
        case GET_CONDOMINI:
            return {
                ...state,
                condomini: payload,
                loading: false
            };
        case CREA_CONDOMINIO:
            let arrayCondomini = [...state.condomini];
            arrayCondomini.push(payload);
            arrayCondomini.sort((a,b) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                condomini: arrayCondomini,
                loading: false
            };
        case GET_CONDOMINI_CONSIGLIERI: 
        return {
            ...state,
            consiglieri: payload,
            loading: false
        };
        case CONDOMINIO_ERROR:
            return {
                ...state,
                condomini: [],
                consiglieri: [],
                condominio: null,
                loading: false
            };
        default:
            return state;
    }
}