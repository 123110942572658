import { GET_CONDOMINO, GET_TUTTI_CONDOMINO, GET_CONDOMINI_CONDOMINIO, GET_CATEGORIE_CONDOMINO, CREA_CATEGORIA_CONDOMINO, GET_CONDOMINI_ACCETTATI, GET_CONDOMINI_NON_ACCETTATI, ACCETTA_CONDOMINI, MODIFICA_CONDOMINO, GET_CONDOMINI_CATEGORIA, CONDOMINO_ERROR } from '../actions/types';

const initialState = {
    condominiUser: [],
    condominoUser: null,
    categorieCondomino: [],
    loading: true
}

export default function auth (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_CONDOMINO:
        case MODIFICA_CONDOMINO:
            return {
                ...state,
                condominoUser: payload,
                loading: false
            };
        case GET_CONDOMINI_CONDOMINIO:
        case GET_TUTTI_CONDOMINO:
        case GET_CONDOMINI_NON_ACCETTATI:
        case GET_CONDOMINI_ACCETTATI:
        case ACCETTA_CONDOMINI:
        case GET_CONDOMINI_CATEGORIA:
            return {
                ...state,
                condominiUser: payload,
                loading: false
            };
        case GET_CATEGORIE_CONDOMINO:
            return {
                ...state,
                categorieCondomino: payload,
                loading: false
            };
        case CREA_CATEGORIA_CONDOMINO:
            let arrayCategorieCondomino = [...state.categorieCondomino];
            arrayCategorieCondomino.push(payload);
            arrayCategorieCondomino.sort((a,b) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : ((b.nome.toLowerCase() > a.nome.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                categorieCondomino: arrayCategorieCondomino,
                loading: false
            };
        case CONDOMINO_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}