import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import Moment from 'react-moment'
import 'moment/locale/it';
import { getNotificaCondomino, leggiNotifica } from '../../actions/notifica';
import { getCondominiConsigliere } from '../../actions/condominio';

const Dashboard = ({ auth: {user, loading}, getNotificaCondomino, notifica: {notifica}, leggiNotifica, alert, getCondominiConsigliere, condominio: {consiglieri} }) => {

    const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
    nomeCondominio: '',
    indirizzo: '',
    urlImmagine: '',
    nomeBanca: '',
    iban: '',
    codicefiscale: '',
    periodoGestione: '',
    foglioMappale: '',
    immagineFoglioMappa: '',
    noteCondominio: '',
    idCondominio: '',
    documenti: [],
    numeriUtili: []
	});
  const dateNow = new Date()

	useEffect(() => {
		setUtente({
			_id: loading || !user ? '' : user._id,
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.ruolo,
      nomeCondominio: loading || !user || !user.condominio ? '' : user.condominio.nome,
      indirizzo: loading || !user || !user.condominio ? '' : user.condominio.indirizzo,
      urlImmagine: loading || !user || !user.condominio ? '' : user.condominio.urlImmagine,
      nomeBanca: loading || !user || !user.condominio ? '' : user.condominio.nomeBanca,
      iban: loading || !user || !user.condominio ? '' : user.condominio.iban,
      codicefiscale: loading || !user || !user.condominio ? '' : user.condominio.codicefiscale,
      periodoGestione: loading || !user || !user.condominio ? '' : user.condominio.periodoGestione,
      foglioMappale: loading || !user || !user.condominio ? '' : user.condominio.foglioMappale,
      immagineFoglioMappa: loading || !user || !user.condominio ? '' : user.condominio.immagineFoglioMappa,
      noteCondominio: loading || !user || !user.condominio ? '' : user.condominio.noteCondominio,
      idCondominio: loading || !user || !user.condominio ? '' : user.condominio._id,
      documenti: loading || !user || !user.condominio ? [] : user.condominio.documenti,
      numeriUtili: loading || !user || !user.condominio ? [] : user.condominio.numeriUtili
		})
	}, [user, loading]);


	const { _id, nome, cognome, ruolo, nomeCondominio, indirizzo, urlImmagine, nomeBanca, iban, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, idCondominio, documenti, numeriUtili } = utente;

  const [notificaData, setNotificaData] = useState(null)
  const [consiglieriData, setConsiglieriData] = useState([])

  useEffect(() => {
    const size = alert.length;
    if (size > 0) {
      if (alert[size - 1].alertType === "success") {
        setNotificaData(null)
        getNotificaCondomino({idCondomino: _id})
      }
    }
  }, [alert]);

  useEffect(() => {
		getNotificaCondomino({idCondomino: user && user._id})
	}, [user]);

  useEffect(() => {
		setNotificaData(notifica)
	}, [notifica]);

  useEffect(() => {
    if(idCondominio.length > 0){
      getCondominiConsigliere({idCondominio: idCondominio})
    }
	}, [idCondominio]);
  
  useEffect(() => {
    setConsiglieriData(consiglieri)
	}, [consiglieri]);

const onClickCopyText = (e, str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
};

const generaLinkGoogleMaps = (indirizzo) => {
  let link = "https://www.google.com/search?q="
  const array = indirizzo.split(' ')
  array.map((el, i) => {
    if(i === 0){
      link = link+el
    }else{
      link = link+"+"+el
    }
  })
  return link
}

    return (loading ? <Spinner /> :
    <>
    {notificaData ?
    <div class="popup">
      <div class="popup-wrap">
        <div class="popup-container">
          <div class="popup-body center">
            <p>{notificaData.messaggio}</p>
            <a onClick={() => {leggiNotifica({idNotifica: notificaData._id})}} class="btn w-button">OK, prosegui la navigazione</a>
            </div>
            </div>
            </div>
            </div>
            :
            ''
    }
    <div class="section lightblue user-block wf-section">
     <Topbar 
        labelPagina={"Home"}
     />
     <div class="w100">
      <div class="margin mt0">
        <div class="center">
          <h4 class="heading mt0 capitalize"><Moment format='dddd, DD MMMM YYYY'>{dateNow}</Moment></h4>
          <p class="heading-2">{  dateNow.getHours() > 15 ? 'Buonasera' : 'Buongiorno'} {nome} {cognome}</p>
        </div>
      </div>
    </div>
    {ruolo === 'utente' ?
    <div class="w100">
    <div class="margin mt0">
      <div class="colonne-bacheca">
        <div class="w33">
          <div class="container-box">
            <div>
              <p class="heading-3">Il tuo condominio:</p>
              <div>
                <div class="img-condominio">
                  {urlImmagine ? 
                  <img src={urlImmagine} loading="lazy" class="circle"/>
                  :
                  ''
                  }
                  </div>
                <p class="name">Condominio {nomeCondominio}</p>
                <p>Indirizzo: <a href={generaLinkGoogleMaps(indirizzo)} target="_blank">{indirizzo}</a>
                </p>
                <p class="m10">CF: {codicefiscale}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w33">
          <div class="container-box">
            <div>
              <p class="heading-3">Gestione</p>
              <p class="m10">{periodoGestione}</p>
              {nomeBanca ?
                <p class="m10">Nome banca: {nomeBanca}</p>
              :
              ''
              }
               {iban ?
                <p onClick={(e) => {onClickCopyText(e, iban)}} class="m10">IBAN: <strong>{iban}</strong> <img src="assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-f503dc95" alt="" class="icon pointer" /></p>
              :
              ''
              }
            </div>
          </div>
          <div class="container-box">
            <div>
              <p class="heading-3">Estratto di mappa</p>
              <div>
                <div class="img-condominio">
                  {immagineFoglioMappa ?
                  <a href={immagineFoglioMappa} target="_blank">
                  <img src={immagineFoglioMappa} loading="lazy" class="circle"/>
                  </a>
                  :
                  ''
                  }
                </div>
                <p class="m10">{foglioMappale}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w33">
          <div class="container-box">
            <div>
              <p class="heading-3">Numeri utili</p>
            {consiglieriData.length > 0 ?
            <>
            <h4 class="m10">Consiglieri: </h4>
              {consiglieriData.map(el => (
                <p class="m10">- {el.cognome} {el.nome}: {el.telefono1}</p>
                ))}
            </>
            :
            ''
            }
            {numeriUtili.length > 0 ?
            <>
            <h4 class="m10">Altri: </h4>
              {numeriUtili.map(el => (
                <p class="m10">- {el.nome}: {el.telefono}</p>
            ))}
            </>
            :
            ''
            }
            </div>
          </div>
        </div>
      </div>
      <div class="colonne-bacheca m20">
        <div class="w100">
          <div class="container-box">
            <div>
              <p class="heading-3">Note</p>
              <div>
                <p class="m10">{noteCondominio}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="colonne-bacheca m20">
        <div class="w100">
          <div class="container-box">
            <div>
              <p class="heading-3">Documenti fac-simili</p>
              {documenti.map(el => (
              <div class="lista-file zoom">
                <div class="file zoom">
                  <a href={el.url} target="_blank" class="no-underline w-inline-block">
                    <div class="file-wrap zoom">
                      <div class="icona-doc">
                        <div class="condiviso-file condiviso zoom"></div>
                        <img src="/assets/media/file-pubblico.png" loading="lazy" alt="" class="preview-file zoom"/>
                      </div>
                      <div>
                        <p>{el.nome}</p>
                      </div>
                    </div>
                  </a>
                </div>
                </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    :
    ''
    }
    </div>   
    </>  
    )
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  notifica: PropTypes.object.isRequired,
  condominio: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  getNotificaCondomino: PropTypes.func.isRequired,
  leggiNotifica: PropTypes.func.isRequired,
  getCondominiConsigliere: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    notifica: state.notifica,
    alert: state.alert,
    condominio: state.condominio
});

export default connect(mapStateToProps, { getNotificaCondomino, leggiNotifica, getCondominiConsigliere })(Dashboard);