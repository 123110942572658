import React from 'react';

const CartellaElement = ({ onClickListFolder, nomeCartella, urlCartella }) => {

  return (
    <div onClick={() => onClickListFolder(urlCartella)} class="item-categoria">
      <a class="link-categoria no-underline w-inline-block">
        <p class="title-cat">{nomeCartella}</p>
        <p class="title-cat">➡️</p>
      </a>
    </div>
  )
};

export default CartellaElement;