import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';
import { toast } from 'react-toastify';

export const setAlert = (msg, alertType, field, timeout = 3000) => dispatch => {
    const id = uuidv4();
    switch(alertType) {
        case 'success':
            toast.success(msg, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
                className	: "alert-success"
            });
          break;
        case 'error':
            toast.error(msg, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
            });
          break;
        default:
          // code block
      }
    dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id, field }
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};