// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDmQ1ffTaWgXsYCvNRMTcVIHTMYgfbdScY",
    authDomain: "amministrazione2t-d94b0.firebaseapp.com",
    projectId: "amministrazione2t-d94b0",
    storageBucket: "amministrazione2t-d94b0.appspot.com",
    messagingSenderId: "527237891755",
    appId: "1:527237891755:web:97280e64b9502d1c86d9ee"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);