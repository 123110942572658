import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { getCategorieCondomino, creaCategoriaCondomino, getCondominiPerCategoria } from '../../actions/condomino';

const CategorieCondomino = ({ auth: { user, loading }, condomino: { categorieCondomino, condominiUser }, getCategorieCondomino, creaCategoriaCondomino, alert, getCondominiPerCategoria }) => {

  const [nomeCategoria, setNomeCategoria] = useState("");
  const [categorieCondominoData, setCategorieCondominoData] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState("");
  const [condominiData, setCondominiData] = useState([]);
  
  
  useEffect(() => {
    getCategorieCondomino()
  }, []);

  useEffect(() => {
    const size = alert.length;
    if(size>0){
        if(alert[size-1].alertType === "success"){
          setNomeCategoria("");
        }
    }
}, [alert]);

  useEffect(() => {
    setCategorieCondominoData(categorieCondomino)
  }, [categorieCondomino]);

  useEffect(() => {
    setCondominiData(condominiUser)
  }, [condominiUser]);

  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
}



const onChange = e => setNomeCategoria(e.target.value);

const onSubmit = async e => {
  e.preventDefault();
  creaCategoriaCondomino({ nome: nomeCategoria });
};

const onChangeCategoria = (nome) => {
  setCategoriaSelected(nome)
  getCondominiPerCategoria({nomeCategoria : nome})
}


  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Categorie condomino"}
        isFolder={false}
      />
      <div class="w100">
      <div class="margin mt0">
        <div class="colonne">
          <div class="w50">
            <div class="form-wrap wide mt0">
              <div class="form-block w-form">
                <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" onSubmit={e => onSubmit(e)} class="form">
                  <h3 id="w-node-a90294b5-464b-9bbb-81f2-33bae37026d8-d15f29e1" class="no-margin mt0">Crea nuova categoria</h3>
                  <div id="w-node-a90294b5-464b-9bbb-81f2-33bae37026da-d15f29e1" class="column">
                    <label for="Nome" class="form-label">Nome</label>
                    <input type="text" class="input-field w-input" maxlength="256" name="Nome" data-name="Nome" placeholder="Nome" id="Nome" value={nomeCategoria} onChange={e => onChange(e)}/>
                  </div>
                 <input type="submit" value="Crea categoria" data-wait="Un attimo..." class="submit-button w-button"/>
                </form>
              </div>
            </div>
          </div>
          <div class="w50">
            <div class="container-box">
              <div>
                <p class="heading-3">Tutte le categorie:</p>
              </div>
              <div class="lista-categorie">
                {categorieCondominoData.map(el => (
                  <div class="categoria">
                  <a href="#" class="no-underline w-inline-block">
                    <div class="categorie-wrap">
                      <div>
                        <p>{el.nome}</p>
                      </div>
                    </div>
                  </a>
                </div>
                ))}
              </div>
            </div>
          </div>
          <div class="w100">
            <div class="list-gestione">
              <div class="w50">
                <div class="w100">
                  <div class="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form">
                      <div id="w-node-_51302091-a96a-15b3-ad1c-2dcded061382-5937f26f" class="column">
                        <label for="Industry-select" class="form-label">Filtra per categoria</label>
                        <select id="Industry-select" name="Industry-select" data-name="Industry select" class="select-field w-select" onChange={(e) => {onChangeCategoria(e.target.value)}}>
                          <option selected={categoriaSelected === ""} value="">Seleziona categoria</option>
                          {categorieCondominoData.map(el => (
                            <option selected={categoriaSelected === el.nome} value={el.nome}>{el.nome}</option>
                            ))}
                        </select></div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="wide-responsive">
                <div class="w-layout-grid utente-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <p>Nome Cognome</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4a-5937f26f">
                    <p>Nome condominio</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>1</sup></p>
                  </div> 
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>2</sup></p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-5937f26f">
                    <p>Telefono</p>
                  </div>
                </div>
                <div>
                  {condominiData.map((el) => (
                    <div class="w-layout-grid utente-grid">
                    <a id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-5937f26f" href={`modifica-condomino/${el._id}`} class="item-grid w-inline-block">
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-5937f26f">
                        <p>{el.nome} {el.cognome}</p>
                      </div>
                    </a>
                    <a id="w-node-_4998b544-e2a8-52f7-0b3f-4ed35686f90a-5937f26f" href="#" class="item-grid w-inline-block">
                      <div>
                        <p>{el.condominio.nome}</p>
                      </div>
                    </a>
                    {el.email1 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {console.log(e, el.email1)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <p>{el.email1}</p>
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                    {el.email2 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {console.log(e, el.email2)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <p>{el.email2}</p>
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                    {el.telefono1 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {console.log(e, el.telefono1)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <p>{el.telefono1}</p>
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                  </div>
                  ))}
                </div>
                <div class="w-layout-grid utente-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <p>Nome Cognome</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4a-5937f26f">
                    <p>Nome condominio</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>1</sup></p>
                  </div> 
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>2</sup></p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-5937f26f">
                    <p>Telefono</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w50"></div>
        </div>
      </div>
    </div>
    </div>
  )
};

CategorieCondomino.propTypes = {
  auth: PropTypes.object.isRequired,
  condomino: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  getCategorieCondomino: PropTypes.func.isRequired,
  creaCategoriaCondomino: PropTypes.func.isRequired,
  getCondominiPerCategoria: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  condomino: state.condomino,
  alert: state.alert
});

export default connect(mapStateToProps, { getCategorieCondomino, creaCategoriaCondomino, getCondominiPerCategoria })(CategorieCondomino);