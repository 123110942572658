import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { logout } from '../../actions/auth';
import { getLogo } from '../../actions/impostazione';



const Sidebar = ({ logout, impostazione: {logo}, getLogo }) => {


	const [pathPage, setPathPage] = useState("/");
	const [classToggle, setClassToggle] = useState(false)
	const [logoSideBar, setLogoSideBar] = useState("")


	useEffect(() => {
		setLogoSideBar(logo)
	}, [logo]);

	useEffect(() => {
		setPathPage(window.location.pathname)
		getLogo()
	}, []);


	const generaMenu = () => {
		return (
			<nav role="navigation" class="nav-menu-4 w-nav-menu">
			<div class="div-block-7">
			<div class="div-block-8">
					<a href="/" onClick={() => setPathPage("/")} className={`nav-link w-nav-link${pathPage === '/' ? ' w--current' : ''}`}>Home</a>
					<a href="/documenti-generali" onClick={() => setPathPage("/documenti-generali")} className={`nav-link w-nav-link${pathPage === '/documenti-generali' ? ' w--current' : ''}`}>Documenti generali</a>
					<a href="/gestioni" onClick={() => setPathPage("/gestioni")} className={`nav-link w-nav-link${pathPage === '/gestioni' ? ' w--current' : ''}`}>Gestioni</a>
					<a href="/verbali-assemblea" onClick={() => setPathPage("/verbali-assemblea")} className={`nav-link w-nav-link${pathPage === '/verbali-assemblea' ? ' w--current' : ''}`}>Verbali Assemblea</a>
					<a href="/documenti" onClick={() => setPathPage("/documenti")} className={`nav-link w-nav-link${pathPage === '/documenti' ? ' w--current' : ''}`}>Documenti</a>
					<a href="/documenti-privati" onClick={() => setPathPage("/documenti-privati")} className={`nav-link w-nav-link${pathPage === '/documenti-privati' ? ' w--current' : ''}`}>Documenti privati</a>
				</div>
				<div class="div-block-6">
					<a href="/modifica-profilo" onClick={() => setPathPage("/modifica-profilo")} className={`nav-link w-nav-link${pathPage === '/modifica-profilo' ? ' w--current' : ''}`}>Modifica profilo</a>
					<a href="mailto:online@amministrazioni2t.com?subject=Assistenza%20Portale%20Condomini" class="nav-link user-link w-nav-link">Assistenza</a>
					<a class="nav-link user-link w-nav-link" href="" onClick={() => logout()}>Logout</a>
				</div>
			</div>
		</nav>
		)
	}

	return (
		<div class="area-user wf-section">
			<div class="wrap-user">
				<div class="navigation-area">
					<div data-animation="over-left" data-collapse="small" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
						<div class="container-2 w-container">
							<a href="/" aria-current="page" class="brand w-nav-brand w--current"><img src={logoSideBar} loading="lazy" alt="" class="logo-header" /></a>
							<div class="div-block-4">
							<div class="div-block-4">
								{classToggle ? '' : generaMenu()}
							</div>
							</div>
							<div class="menu-button w-nav-button" onClick={() => setClassToggle(!classToggle)}>
								<div class="icon w-icon-nav-menu"></div>
							</div>
							<div class={`w-nav-overlay${classToggle ? ' hamburger-sel' : ''}`} data-wf-ignore="" id="w-nav-overlay-0">
								{classToggle ? generaMenu() : ''}
							</div>						
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

Sidebar.protoTypes = {
	logout: PropTypes.func.isRequired,
	getLogo: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	impostazione: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	impostazione: state.impostazione
});

export default connect(mapStateToProps, { logout, getLogo })(Sidebar);

