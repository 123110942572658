import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import documento from './documento';
import condomino from './condomino';
import condominio from './condominio';
import notifica from './notifica';
import impostazione from './impostazione';



export default combineReducers ({
    alert,
    auth,
    documento,
    condomino,
    condominio,
    notifica,
    impostazione
});