import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { getCondomini } from '../../actions/condominio';
import { creaCondomino, getCategorieCondomino } from '../../actions/condomino';

const CreaCondomino = ({ auth: { user, loading }, getCondomini, alert, condominio: {condomini}, creaCondomino, getCategorieCondomino, condomino: { categorieCondomino }}) => {

  const initialState = {
    active: false,
    nome: '',
    cognome: '',
    codicefiscale: '',
    password: '',
    email1: '',
    email2: '',
    luogoNascita: '',
    dataNascita: '',
    telefono1: '',
    telefono2: '',
    idCondominio: '',
    urlDocumentiPrivati: '',
    nomeCondominoEstrattoConto: '',
    nomeCondominoBollettinoDiPagamento: '',
    categorie: []
  }

  const [condominoData, setCondominoData] = useState(initialState);
  const [condominioData, setCondominioData] = useState([]);
  const [categorieCondominoData, setCategorieCondominoData] = useState([]);

  const { active, nome, cognome, codicefiscale, password, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, idCondominio, urlDocumentiPrivati, categorie } = condominoData;

  useEffect(() => {
    const size = alert.length;
    if (size > 0) {
      if (alert[size - 1].alertType === "success") {
        setCondominoData(initialState);
      }
    }
  }, [alert]);

  useEffect(() => {
    setCategorieCondominoData(categorieCondomino)
  }, [categorieCondomino]);

  useEffect(() => {
    getCondomini()
    getCategorieCondomino()
  }, []);

  useEffect(() => {
    setCondominioData(condomini)
  }, [condomini]);

  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
  }

  const onChange = e => setCondominoData({ ...condominoData, [e.target.name]: e.target.value });
  const onSubmit = async e => {
    e.preventDefault();
    creaCondomino({ active, nome, cognome, codicefiscale, password, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, idCondominio, urlDocumentiPrivati, categorie });
  };

  const onChangeCheckboxCategorie = (categoria) => {
    let arrayCategorie = categorie
    if(arrayCategorie.includes(categoria)){
      arrayCategorie = arrayCategorie.filter(el => (el !== categoria))
    }else{
      arrayCategorie.push(categoria)
    }
    setCondominoData({ ...condominoData, categorie: arrayCategorie })
  }


  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Crea condomino"}
        isFolder={false}
      />
      <div class="container flex-center">
        <div class="form-wrap wide">
          <div class="form-block w-form">
            <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" class="form" onSubmit={e => onSubmit(e)}>
              <h3 id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b9375a-347debdf" class="no-margin">Registrazione condomino</h3>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b9375c-347debdf" class="column">
                <label for="nome" class="form-label">Nome <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="nome" data-name="nome" placeholder="Nome" id="nome" required="true" value={nome} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93760-347debdf" class="column">
                <label for="cognome" class="form-label">Cognome <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="cognome" data-name="cognome" placeholder="Cognome" id="cognome" required="true"  value={cognome} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="password" class="form-label">Password <span class="required">*</span></label>
                <input type="password" class="input-field w-input" maxlength="256" name="password" data-name="password" placeholder="Password" id="password" required="true" value={password} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="codicefiscale" class="form-label">Codice fiscale <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="codicefiscale" data-name="codicefiscale" placeholder="Codice fiscale" id="codicefiscale" required="true" value={codicefiscale} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-e7570c72-74ce-9da3-f85d-1baca43dd69a-1ef19627" class="column">
                <label for="Industry-select" class="form-label">Condominio <span class="required">*</span></label>
                <select id="Industry-select" name="idCondominio" data-name="Industry select" required="true" class="select-field w-select" onChange={e => onChange(e)}>
                  <option selected value="">Seleziona condominio</option>
                  {condominioData.map(el => (
                    <option value={el._id}>{el.nome}</option>
                  ))}
                </select>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="email1" class="form-label">Indirizzo email <sup>1</sup><span class="required">*</span></label>
                <input type="email1" class="input-field w-input" maxlength="256" name="email1" data-name="email1" placeholder="Email" id="email1" required="true" value={email1} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="email2" class="form-label">Indirizzo email <sup>2</sup></label>
                <input type="email2" class="input-field w-input" maxlength="256" name="email2" data-name="email2" placeholder="Email" id="email2" value={email2} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="luogoNascita" class="form-label">Luogo di nascita <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="luogoNascita" data-name="luogoNascita" placeholder="Luogo di nascita" required="true" id="luogoNascita" value={luogoNascita} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="dataNascita" class="form-label">Data di nascita <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="dataNascita" data-name="dataNascita" placeholder="Data di nascita" required="true" id="dataNascita" value={dataNascita} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="telefono1" class="form-label">Telefono <sup>1</sup><span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="telefono1" data-name="telefono1" placeholder="Telefono 1" required="true" id="telefono1" value={telefono1} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="telefono2" class="form-label">Telefono <sup>2</sup></label>
                <input type="text" class="input-field w-input" maxlength="256" name="telefono2" data-name="telefono2" placeholder="Telefono 2" id="telefono2" value={telefono2} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="Industry-select" class="form-label">Nome condomino su PDF estratto conto</label>
                <input type="text" class="input-field w-input" maxlength="256" name="nomeCondominoEstrattoConto" data-name="nomeCondominoEstrattoConto" placeholder="Nome condomino su PDF estratto conto" id="nomeCondominoEstrattoConto" value={nomeCondominoEstrattoConto} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="Industry-select" class="form-label">Nome condomino su PDF bollettino di pagamento</label>
                <input type="text" class="input-field w-input" maxlength="256" name="nomeCondominoBollettinoDiPagamento" data-name="nomeCondominoBollettinoDiPagamento" placeholder="Nome condomino su PDF bollettino di pagamento" id="nomeCondominoBollettinoDiPagamento" value={nomeCondominoBollettinoDiPagamento} onChange={e => onChange(e)}/>
              </div>
              <div id="w-node-c2bc9a2c-906b-af5e-88f5-289f2407ad3c-347debdf" class="separator">
                <p class="subtitle center">Categorie condomino</p>
              </div>
              <div id="w-node-_93df8d43-c857-6cd5-0f3e-03c7dbc59aa5-347debdf" class="column margin-bot-xs">
                <label for="Full-Name-6" class="form-label">Categorie</label>
                <div class="radio-buttons-wrap">
                  {categorieCondominoData.map(el => (
                    <label id="w-node-_93df8d43-c857-6cd5-0f3e-03c7dbc59aa9-347debdf" class="input-field radio w-radio" onChange={() => onChangeCheckboxCategorie(el.nome)}>
                    <div class="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input"></div>
                    <input type="checkbox" id={el._id} data-name="Pricing Plan" checked={categorie.includes(el.nome)}/>
                    <span class="w-form-label" for="Basic">{el.nome}</span>
                  </label>
                  ))}
                </div>
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93786-347debdf" class="w-embed">
                <input type="hidden" class="utm_source" placeholder="utm_source" name="utm_source" />
                <input type="hidden" class="utm_medium" placeholder="utm_medium" name="utm_medium" />
                <input type="hidden" class="utm_campaign" placeholder="utm_campaign" name="utm_campaign" />
                <input type="hidden" name="Test Hidden Field" value="Test" />
              </div>
              <input type="submit" value="Registra ora il condomino" data-wait="Un attimo..." id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93788-347debdf" class="submit-button w-button" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};

CreaCondomino.propTypes = {
  auth: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  condominio: PropTypes.object.isRequired,
  condomino: PropTypes.object.isRequired,
  getCondomini: PropTypes.func.isRequired,
  creaCondomino: PropTypes.func.isRequired,
  getCategorieCondomino: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  alert: state.alert,
  condominio: state.condominio,
  condomino: state.condomino,
});

export default connect(mapStateToProps, { getCondomini, creaCondomino, getCategorieCondomino })(CreaCondomino);