import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Topbar from '../layout/Topbar';
import Spinner from '../layout/Spinner';

const Changelog = ({ auth: { user, loading } }) => {



  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar 
        labelPagina={`Changelog <span class="text-span-2"> > </span> Version 1 changelog`}
        isFolder={false}
     />
      <div class="w100">
        <div class="margin mt0">
          <h1>Version 1 changelog</h1>
          <h1>Version 1.0.0 — 22 gennaio, 2023</h1>
        </div>
      </div>
    </div>
  )
};

Changelog.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Changelog);