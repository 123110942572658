import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Topbar = ({ labelPagina, isFolder, onClickBackListFolder }) => {

	return (
		<div class="w100">
      <div class="margin">
        <div class="esterni">
          <div class="titolo">
            <h1>
              <span dangerouslySetInnerHTML={ {__html: labelPagina} } ></span>
              {isFolder ? 
                  <img src="/assets/media/return.png" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-f503dc95" alt="" class="icon pointer return" onClick={() => onClickBackListFolder()}></img>
                  :
              ''
              }
            </h1>
          </div>
          <div class="destra">
            <div class="user-block">
              <a href="/modifica-profilo" class="w-inline-block"><img src="/assets/media/profile.svg" loading="lazy" alt="" class="image"/></a>
            </div>
          </div>
        </div>
      </div>
</div> 

	)
}

Topbar.protoTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps, {})(Topbar);