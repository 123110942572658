import axios from 'axios';
import { setAlert } from './alert';
import { GET_CONDOMINO, GET_TUTTI_CONDOMINO, GET_CONDOMINI_CONDOMINIO, CREA_CONDOMINO, GET_CATEGORIE_CONDOMINO, CREA_CATEGORIA_CONDOMINO, GET_CONDOMINI_NON_ACCETTATI, GET_CONDOMINI_ACCETTATI, ACCETTA_CONDOMINI, MODIFICA_CONDOMINO, GET_CONDOMINI_CATEGORIA, CONDOMINO_ERROR } from './types';


export const getCondomino= ({ idCondomino }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondomino });
    try{
        const res = await axios.post('/api/condomino/getCondomino', body, config);
        dispatch({
            type: GET_CONDOMINO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const getTuttiCondomino= () => async dispatch => {
    try{
        const res = await axios.get('/api/condomino/getTuttiCondomino');
        dispatch({
            type: GET_TUTTI_CONDOMINO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const getCondominiCondominio = ({ idCondominio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio });
    try{
        const res = await axios.post('/api/condomino/getCondominiCondominio', body, config);
        dispatch({
            type: GET_CONDOMINI_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const creaCondomino = ({ active, idCondominio, nome, cognome, codicefiscale, password, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, urlDocumentiPrivati, categorie  }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ active, idCondominio, nome, cognome, codicefiscale, password, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, urlDocumentiPrivati, categorie  });
    try{
        const res = await axios.post('/api/auth/registraNuovoCondomino', body, config);
        dispatch({
            type: CREA_CONDOMINO
        });
        dispatch(setAlert("Registrazione effettuata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
};

export const modificaCondomino = ({ idCondomino, active, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, idCondominio, categorie }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ idCondomino, active, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominoEstrattoConto, nomeCondominoBollettinoDiPagamento, idCondominio, categorie });
    try{
        const res = await axios.post('/api/condomino/modificaCondomino', body, config);
        dispatch({
            type: MODIFICA_CONDOMINO,
            payload: res.data
        });
        dispatch(setAlert("Modifica effettuata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
};

export const getCategorieCondomino= () => async dispatch => {
    try{
        const res = await axios.get('/api/condomino/getCategorieCondomino');
        dispatch({
            type: GET_CATEGORIE_CONDOMINO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const creaCategoriaCondomino = ({ nome }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ nome });
    try{
        const res = await axios.post('/api/condomino/creaCategoriaCondomino', body, config);
        dispatch({
            type: CREA_CATEGORIA_CONDOMINO,
            payload: res.data
        });
        dispatch(setAlert("Categoria creata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const getCondominiNonAccettati = () => async dispatch => {
    try{
        const res = await axios.get('/api/condomino/getCondominiNonAccettati');
        dispatch({
            type: GET_CONDOMINI_NON_ACCETTATI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const getCondominiAccettati = () => async dispatch => {
    try{
        const res = await axios.get('/api/condomino/getCondominiAccettati');
        dispatch({
            type: GET_CONDOMINI_ACCETTATI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}



export const accettaCondomini = ({ condomini }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ condomini });
    try{
        const res = await axios.post('/api/condomino/accettaCondomini', body, config);
        dispatch({
            type: ACCETTA_CONDOMINI,
            payload: res.data
        });
        dispatch(setAlert("Condomini accettati con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}

export const getCondominiPerCategoria = ({ nomeCategoria }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ nomeCategoria });
    try{
        const res = await axios.post('/api/condomino/getCondominiPerCategoria', body, config);
        dispatch({
            type: GET_CONDOMINI_CATEGORIA,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINO_ERROR
        });
    }
}