import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modificaPassword, checkToken } from '../../actions/auth';

const NuovaPassword = ({ alert, modificaPassword, isAuthenticated, checkToken }) => {

    let { token } = useParams();
    const [redirect, setRedirect] = useState(false)


    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "success"){
                setSuccessForm(true)
            }
            if(alert[size-1].alertType === "redirect"){
                setRedirect(true)
            }
        }
    }, [alert]);

    useEffect(() => {
        checkToken({token: token})
    }, []);

    const [successForm, setSuccessForm] = useState(false)


    const [formData, setFormData] = useState({
        password: ''
    });


    const { password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        modificaPassword({ password, token });
    };

    if (isAuthenticated) {
        return <Redirect to="/" />
    }
    if (redirect) {
        return <Redirect to="/" />
    }

    return (
        <div id="registrazione-condomino" class="accedi wf-section">
            <div class="container flex-center">
                <div class="form-wrap"><img src="/assets/media/logo-amministrazioni2t.png" loading="lazy" alt="" class="logo"/>
                    <div class="form-block w-form">
                        {successForm ?
                        <div class="container flex-center">
                        <h3 class="no-margin center">Password modificata con successo!</h3>
                        <p class="m10 center">Adesso puoi accedere con la tua nuova password.</p>
                        <a href="/login" alt="logo" class="m10 center">Vai al login</a>
                        </div>
                        :
                        <form id="wf-form-login-form" name="wf-form-login-form" data-name="login form" class="form" onSubmit={e => onSubmit(e)}>
                            <h3 id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d03-40413f47" class="no-margin">Imposta una nuova password</h3>
                            <div id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d0d-40413f47" class="column">
                                <label for="password" class="form-label">Nuova password</label>
                                <input type="password" class="input-field company w-input" maxlength="256" name="password" data-name="password" placeholder="Password" id="password" required="true" value={password} onChange={e => onChange(e)}/>
                            </div>
                            <input type="submit" value="Salva" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button"/>
                        </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

NuovaPassword.propTypes = {
    modificaPassword: PropTypes.func.isRequired,
    checkToken: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    alert: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    alert: state.alert
});

export default connect(mapStateToProps, { modificaPassword, checkToken })(NuovaPassword);