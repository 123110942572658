import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from '../routing/PrivateRoute';
import Sidebar from '../layout/Sidebar';
import SidebarAdmin from '../layout/SidebarAdmin';
import Footer from '../layout/Footer';
import Login from '../auth/Login';
import RecuperaPassword from '../auth/RecuperaPassword';
import NuovaPassword from '../auth/NuovaPassword';
import Registrazione from '../auth/Registrazione';
import DocumentiGenerali from '../documentiGenerali/DocumentiGenerali';
import DocumentiGestioni from '../documentiGestioni/DocumentiGestioni';
import DocumentiVerbaliAssemblea from '../documentiVerbaliAssemblea/DocumentiVerbaliAssemblea';
import DocumentiDocumenti from '../documentiDocumenti/DocumentiDocumenti';
import DocumentiPrivati from '../documentiPrivati/DocumentiPrivati';
import DocumentiGeneraliAdmin from '../documentiGenerali/DocumentiGeneraliAdmin';
import DocumentiPrivatiAdmin from '../documentiGenerali/DocumentiPrivatiAdmin';
import ModificaProfilo from '../condomino/ModificaProfilo';
import GestioneCondominio from '../admin/GestioneCondominio';
import CreaCondominio from '../admin/CreaCondominio';
import ModificaCondominio from '../admin/ModificaCondominio';
import GestioneCondomino from '../admin/GestioneCondomino';
import CategorieCondomino from '../admin/CategorieCondomino';
import CreaCondomino from '../admin/CreaCondomino';
import AccettaCondomino from '../admin/AccettaCondomino';
import ModificaCondomino from '../admin/ModificaCondomino';
import GestioneNotifiche from '../admin/GestioneNotifiche';
import ImpostazioniGenerali from '../impostazioni/ImpostazioniGenerali';
import Changelog from '../changelog/Changelog';


import NotFound from '../layout/NotFound';
import Dashboard from '../dashboard/Dashboard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const generaSidebar = ({isAuthenticated, ruolo}) => {
  if(isAuthenticated){
    if(ruolo === 'admin'){
      return <SidebarAdmin />
    }else{
      return <Sidebar />
    }
  }else{
    return ''
  }
}

const Routes = ({auth: { user, isAuthenticated }}) => {
    return (
      <BrowserRouter>
        {generaSidebar({isAuthenticated, ruolo: user && user.ruolo})}
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/documenti-generali" component={DocumentiGenerali} />
          <PrivateRoute exact path="/gestioni" component={DocumentiGestioni} />
          <PrivateRoute exact path="/verbali-assemblea" component={DocumentiVerbaliAssemblea} />
          <PrivateRoute exact path="/documenti" component={DocumentiDocumenti} />
          
          <PrivateRoute exact path="/documenti-privati" component={DocumentiPrivati} />
          <PrivateRoute exact path="/modifica-profilo" component={ModificaProfilo} />
          <PrivateRoute exact path="/changelog" component={Changelog} />
          
          <PrivateRoute exact path="/gestione-condominio" component={GestioneCondominio} />
          <PrivateRoute exact path="/crea-condominio" component={CreaCondominio} />
          <PrivateRoute path="/modifica-condominio/:idCondominio" component={ModificaCondominio} />
          <PrivateRoute exact path="/documenti-generali-admin/:idCondominio" component={DocumentiGeneraliAdmin} />
          <PrivateRoute exact path="/documenti-privati-admin/:idCondomino/:idCondominio" component={DocumentiPrivatiAdmin} />

          

          <PrivateRoute exact path="/gestione-condomino" component={GestioneCondomino} />
          <PrivateRoute exact path="/accetta-condomino" component={AccettaCondomino} />
          <PrivateRoute exact path="/crea-condomino" component={CreaCondomino} />
          <PrivateRoute exact path="/categorie-condomino" component={CategorieCondomino} />
          <PrivateRoute path="/modifica-condomino/:idCondomino" component={ModificaCondomino} />

          <PrivateRoute exact path="/gestione-notifiche" component={GestioneNotifiche} />
          <PrivateRoute exact path="/impostazioni-generali" component={ImpostazioniGenerali} />

          <Route exact path="/login" component={Login} />
          <Route path="/registrazione/:linkRegistrazione" component={Registrazione} />
          <Route exact path="/recupero-password" component={RecuperaPassword}/>
          <Route exact path="/nuova-password/:token" component={NuovaPassword} />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </BrowserRouter>
    )
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Routes);

