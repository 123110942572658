import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link } from "react-router-dom";
import { getLogo } from '../../actions/impostazione';

const SidebarAdmin = ({ logout, impostazione: {logo}, getLogo }) => {

	
	const [classToggle, setClassToggle] = useState(false)
	const [pathPage, setPathPage] = useState("/");
	const [logoSideBar, setLogoSideBar] = useState("")

	useEffect(() => {
		setLogoSideBar(logo)
	}, [logo]);

	useEffect(() => {
		getLogo()
		setPathPage(window.location.pathname)
	}, []);


	const generaMenu = () => {
		return (
			<nav role="navigation" class="nav-menu-4 w-nav-menu">
				<div class="div-block-7">
					<div class="div-block-8">
						<ul role="list" class="list-gestione">
							<li class="list-item-2">
								<a href="/" onClick={() => setPathPage("/")} className={`nav-link w-nav-link${pathPage === '/' ? ' w--current' : ''}`}>Home</a>
							</li>
							<li>
								<a href="/gestione-condominio" onClick={() => setPathPage("/gestione-condominio")} className={`nav-link w-nav-link`}>Gestione condominio</a>
								<div class="child">
									<a href="/gestione-condominio" onClick={() => setPathPage("/gestione-condominio")} className={`nav-link child w-nav-link${pathPage === '/gestione-condominio' ? ' w--current' : ''}`}>Vedi tutti condominio</a>
									<a href="/crea-condominio" onClick={() => setPathPage("/crea-condominio")} className={`nav-link child w-nav-link${pathPage === '/crea-condominio' ? ' w--current' : ''}`}>Crea condominio</a>
								</div>
							</li>
							<li>
								<a href="/gestione-condomino" onClick={() => setPathPage("/gestione-condomino")} className={`nav-link w-nav-link`}>Gestione condomino</a>
								<div class="child">
									<a href="/gestione-condomino" onClick={() => setPathPage("/gestione-condomino")} className={`nav-link child w-nav-link${pathPage === '/gestione-condomino' ? ' w--current' : ''}`}>Vedi tutti condomino</a>
									<a href="/accetta-condomino" onClick={() => setPathPage("/accetta-condomino")} className={`nav-link child w-nav-link${pathPage === '/accetta-condomino' ? ' w--current' : ''}`}>Accetta nuovo condomino</a>
									<a href="/crea-condomino" onClick={() => setPathPage("/crea-condomino")} className={`nav-link child w-nav-link${pathPage === '/crea-condomino' ? ' w--current' : ''}`}>Crea condomino</a>
									<a href="/categorie-condomino" onClick={() => setPathPage("/categorie-condomino")} className={`nav-link child w-nav-link${pathPage === '/categorie-condomino' ? ' w--current' : ''}`}>Categorie condomino</a>
								</div>
							</li>
							<li>
								<a href="/impostazioni-generali" onClick={() => setPathPage("/impostazioni-generali")} className={`nav-link w-nav-link`}>Impostazioni</a>
								<div class="child">
								<a href="/impostazioni-generali" onClick={() => setPathPage("/impostazioni-generali")} className={`nav-link child w-nav-link${pathPage === '/impostazioni-generali' ? ' w--current' : ''}`}>Impostazioni generali</a>
								<a href="/gestione-notifiche" onClick={() => setPathPage("/gestione-notifiche")} className={`nav-link child w-nav-link${pathPage === '/gestione-notifiche' ? ' w--current' : ''}`}>Gestione notifiche</a>
								</div>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div class="div-block-6">
						<a href="/modifica-profilo" onClick={() => setPathPage("/modifica-profilo")} className={`nav-link w-nav-link${pathPage === '/modifica-profilo' ? ' w--current' : ''}`}>Modifica profilo</a>
						<a class="nav-link user-link w-nav-link" href="" onClick={() => logout()}>Logout</a>
					</div>
				</div>
			</nav>
		)
	}
	
	return (
		<div class="area-user wf-section">
			<div class="wrap-user">
				<div class="navigation-area">
					<div data-animation="over-left" data-collapse="small" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
						<div class="container-2 w-container">
							<a href="/" aria-current="page" class="brand w-nav-brand w--current"><img src={logoSideBar} loading="lazy" alt="" class="logo-header" /></a>
							<div class="div-block-4">
								{classToggle ? '' : generaMenu()}
							</div>

							<div class="menu-button w-nav-button" onClick={() => setClassToggle(!classToggle)}>
								<div class="icon w-icon-nav-menu"></div>
							</div>
							<div class={`w-nav-overlay${classToggle ? ' hamburger-sel' : ''}`} data-wf-ignore="" id="w-nav-overlay-0">
							{classToggle ? generaMenu() : ''}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

SidebarAdmin.protoTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	impostazione: PropTypes.object.isRequired,
	getLogo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	impostazione: state.impostazione
});

export default connect(mapStateToProps, { logout, getLogo })(SidebarAdmin);

