import { storage } from "../firebase"
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import FileSaver from "file-saver";

export const getUrlDocumentiCondominioUploaded = (e, nomeCondominio, tipologia) => {
    return new Promise((resolve, reject) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            if (!file) return;
            const storageRef = ref(storage, `documenti/${nomeCondominio}/${tipologia}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                    reject(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                }
            );
        }
      })
}

export const getUrlImmaginiCondominioUploaded = (e) => {
    return new Promise((resolve, reject) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            let randomNum = (Math.random() + 1).toString(36).substring(7);
            if (!file) return;
            const storageRef = ref(storage, `immagini-condomini/${file.name}-${randomNum}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                    reject(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                }
            );
        }
      })
}

export const getUrlImmaginiMappaCondominioUploaded = (e) => {
    return new Promise((resolve, reject) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            let randomNum = (Math.random() + 1).toString(36).substring(7);
            if (!file) return;
            const storageRef = ref(storage, `immagini-mappa-condomini/${file.name}-${randomNum}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                    reject(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                }
            );
        }
      })
}

export const getUrlFileGeneraliUploaded = (e, tipologia) => {
    return new Promise((resolve, reject) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            if (!file) return;
            const storageRef = ref(storage, `generali/${tipologia}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
    
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                    reject(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    });
                }
            );
        }
      })
}

export const apriFileFromBlob = ({documento}) => {
    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }

      if (documento && documento.data) {
        let estensione = documento.nome.slice(documento.nome.lastIndexOf('.'))
        let type = estensione.toLowerCase() === '.pdf' ? 'application/pdf' : estensione.toLowerCase() === '.png' ? 'image/png' : estensione.toLowerCase() === '.jpg' ? 'image/jpg' : estensione.toLowerCase() === '.jpeg' ? 'image/jpeg' : null
        let a = document.createElement("a");
        if (type) {
          const file = new Blob([new Uint8Array(documento.data.data)], { type: type });
          let fileURL = URL.createObjectURL(file);
          fileURL += `#filename=${documento.nome}`
  
          //window.open(fileURL, "_self");
          a.href = fileURL;
          a.target = '_blank';
          // Don't set download attribute
          //a.download = documento.nome;
          //window.open(fileURL, '_blank');
          var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if(iOS()){
            //FileSaver.saveAs(file);
            if(isSafari){
            FileSaver.saveAs(file, documento.nome);
  
            }else{
              a.click();
            }
          }else{
            a.click();
          }
  
        } else {
          a.href = documento.link;
        }
        //a.click();
        //clearlinkDocumento()
      }
}

export const apriFileFromBlobNew = ({documento}) => {
      if (documento && documento.data) {
        let estensione = documento.nome.slice(documento.nome.lastIndexOf('.'))
        let type = estensione.toLowerCase() === '.pdf' ? 'application/pdf' : estensione.toLowerCase() === '.png' ? 'image/png' : estensione.toLowerCase() === '.jpg' ? 'image/jpg' : estensione.toLowerCase() === '.jpeg' ? 'image/jpeg' : null
        let a = document.createElement("a");
        if (type) {
          const file = new Blob([new Uint8Array(documento.data.data)], { type: type });
          let fileURL = URL.createObjectURL(file);
          fileURL += `#filename=${documento.nome}`
        return fileURL
        }
    }
    return null
}