import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        codicefiscale: '',
        password: ''
    });


    const { codicefiscale, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        login({ codicefiscale, password });
    };

    if (isAuthenticated) {
        return <Redirect to="/" />
    }


    return (
        <div id="registrazione-condomino" class="accedi wf-section">
            <div class="container flex-center">
                <div class="form-wrap"><img src="/assets/media/logo-amministrazioni2t.png" loading="lazy" alt="" class="logo"/>
                    <div class="form-block w-form">
                        <form id="wf-form-login-form" name="wf-form-login-form" data-name="login form" class="form" onSubmit={e => onSubmit(e)}>
                            <h3 id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d03-40413f47" class="no-margin">Accedi alla tua area riservata</h3>
                            <div id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d09-40413f47" class="column">
                                <label for="email-3" class="form-label">Codice fiscale</label>
                                <input type="text" class="input-field email w-input" maxlength="256" name="codicefiscale" data-name="codicefiscale" placeholder="Inserisci codice fiscale" id="email-3" required="true" value={codicefiscale} onChange={e => onChange(e)}/>
                            </div>
                            <div id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d0d-40413f47" class="column">
                                <label for="password" class="form-label">Password</label>
                                <input type="password" class="input-field company w-input" maxlength="256" name="password" data-name="password" placeholder="Password" id="password" required="true" value={password} onChange={e => onChange(e)}/>
                            </div>
                            <input type="submit" value="Accedi ora" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button"/>
                        </form>
                        <div class="w100 center m10"><a href="/recupero-password"class="no-underline m10"><i>Hai dimenticato la password?</i></a></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);