import axios from 'axios';
import { setAlert } from './alert';
import { GET_NOTIFICHE, CREA_NOTIFICA, GET_NOTIFICA_CONDOMINO, LEGGI_NOTIFICA, ELIMINA_NOTIFICA, NOTIFICA_ERROR } from './types';

export const getNotifiche = ({ idCondominio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio });
    try{
        const res = await axios.post('/api/notifica/getNotifiche', body, config);
        dispatch({
            type: GET_NOTIFICHE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: NOTIFICA_ERROR
        });
    }
}

export const creaNotifica = ({ idCondomino, idCondominio, messaggio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondomino, idCondominio, messaggio });
    try{
        const res = await axios.post('/api/notifica/creaNotifica', body, config);
        dispatch({
            type: CREA_NOTIFICA,
            payload: res.data
        });
        dispatch(setAlert("Notifica creata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: NOTIFICA_ERROR
        });
    }
}

export const eliminaNotifiche = ({ arrayNotifiche }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ arrayNotifiche });
    try{
        const res = await axios.post('/api/notifica/eliminaNotifiche', body, config);
        dispatch({
            type: ELIMINA_NOTIFICA,
            payload: res.data
        });
        dispatch(setAlert("Notifica eliminata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: NOTIFICA_ERROR
        });
    }
}

export const getNotificaCondomino = ({ idCondomino }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondomino });
    try{
        const res = await axios.post('/api/notifica/getNotificaCondomino', body, config);
        dispatch({
            type: GET_NOTIFICA_CONDOMINO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: NOTIFICA_ERROR
        });
    }
}

export const leggiNotifica = ({ idNotifica }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idNotifica });
    try{
        const res = await axios.post('/api/notifica/leggiNotifica', body, config);
        dispatch({
            type: LEGGI_NOTIFICA,
            payload: res.data
        });
        dispatch(setAlert("Notifica letta con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: NOTIFICA_ERROR
        });
    }
}