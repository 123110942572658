import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import {getUrlFileGeneraliUploaded} from '../../utils/utility'
import { getImpostazioni, modificaImpostazioni } from '../../actions/impostazione';

const ImpostazioniGenerali = ({ auth: { user, loading }, impostazione, getImpostazioni, modificaImpostazioni }) => {

  const initialState = {logo: '', emailNotifiche: [] }
  const [impostazioniData, setImpostazioniData] = useState(initialState);

  useEffect(() => {
    getImpostazioni()
  }, []);

  useEffect(() => {
    setImpostazioniData({
      logo: !impostazione ? '' : impostazione.logo,
      emailNotifiche: !impostazione || !impostazione.emailNotifiche ? [] : impostazione.emailNotifiche
    })
  }, [impostazione]);

  

  const { logo, emailNotifiche } = impostazioniData;

  useEffect(() => {
    const size = alert.length;
    if (size > 0) {
      if (alert[size - 1].alertType === "success") {
        setImpostazioniData(initialState);
      }
    }
  }, [alert]);



  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
  }

  const onSubmit = async e => {
    e.preventDefault();
    modificaImpostazioni({ logo, emailNotifiche });
  };

  const onClickUploadLogo = async (e) => {
    try {
        const data = await getUrlFileGeneraliUploaded(e, "logo")
        setImpostazioniData({ ...impostazioniData, logo: data });
        e.target.value = null;
    } catch (error) {
      console.log(error)
    }
  }

  const onClickEliminaLogo = () => {
    setImpostazioniData({ ...impostazioniData, logo: "" });
  }

  const onClickAggiungiRigaEmail = () => {
    let array = [...emailNotifiche]
    array.push("")
    setImpostazioniData({ ...impostazioniData, emailNotifiche: array });
  }

  const onClickEliminaEmail = (i) => {
    let array = [...emailNotifiche]
    array.splice(i, 1)
    setImpostazioniData({ ...impostazioniData, emailNotifiche: array });
  }

  const onChangeEmail = (e, i) => {
    let array = [...emailNotifiche]
    array[i] = e.target.value
    setImpostazioniData({ ...impostazioniData, emailNotifiche: array });
  }

  console.log(impostazione)
  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Impostazioni generali"}
        isFolder={false}
      />
      <div class="container flex-center">
        <div class="form-wrap wide">
          <div class="form-block w-form">
            <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" class="form" onSubmit={e => onSubmit(e)}>
              <h3 id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b9375a-347debdf" class="no-margin">Impostazioni generali</h3>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="urlImmagine" class="form-label">Logo</label>
                {logo ?
                  <div class="immagine-condominio">
                    <a class="no-underline w-inline-block">
                      <div class="img-wrap">
                        <div class="adiacenti">
                          <a href={logo} alt="logo" target="_blank">
                            <img style={{ width: "30px" }} src={logo}></img></a>
                          <div class="img-del small">
                            <img src="/assets/media/delete.png" loading="lazy" sizes="20px" alt="" class="delete small" onClick={() => onClickEliminaLogo()} />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  :
                  <input type="file" className="input-field w-input" name="logo" data-name="logo" onChange={(e) => onClickUploadLogo(e)} />
                }
              </div>
              {emailNotifiche.map((el, i) => (
                <div id="w-node-_8f3a525e-f3a8-a69e-285d-23863f4bcca5-347debdf" class="adiacenti low">
                  <div class="column w100">
                    <label for="email" class="form-label">Email</label>
                    <input type="text" class="input-field w-input" maxlength="256" name="email" data-name="email" placeholder="Email" id="email" value={el} onChange={e => onChangeEmail(e, i)} />
                  </div>
                  <div class="img-del">
                    <img src="/assets/media/delete.png" loading="lazy" alt="" class="delete small" onClick={() => { onClickEliminaEmail(i) }} />
                  </div>
                </div>
              ))}
              <input value="+" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button action" onClick={() => { onClickAggiungiRigaEmail() }} />
              
              <input type="submit" value="Salva" data-wait="Un attimo..." id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93788-347debdf" class="submit-button w-button" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};

ImpostazioniGenerali.propTypes = {
  auth: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  impostazione: PropTypes.object.isRequired,
  getImpostazioni: PropTypes.func.isRequired,
  modificaImpostazioni: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  alert: state.alert,
  impostazione: state.impostazione
});

export default connect(mapStateToProps, { getImpostazioni, modificaImpostazioni })(ImpostazioniGenerali);