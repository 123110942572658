import QRCode from 'qrcode'
import { useState, useEffect } from 'react'


const QRCodeElement = ({data}) => {

    const [qr, setQr] = useState('')

	useEffect(() => {
		GenerateQRCode(data)
	  }, [data]);
	
    const GenerateQRCode = (data) => {
		let testo = "https://condomini.amministrazioni2t.com/registrazione/"+data
        QRCode.toDataURL(testo, {
            width: 800,
            margin: 2,
            color: {
                dark: '#182771',
                light: '#FFF'
            }
        }, (err, url) => {
            if (err) return console.error(err)
            console.log(url)
            setQr(url)
        })
	}

	
	return (
		qr && <>
			<a variant='contained' color='success' href={qr} download="qrcode.png"><img src={qr} /></a>
		</>
	)
}

export default (QRCodeElement);

