import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUrlImmaginiMappaCondominioUploaded, getUrlImmaginiCondominioUploaded } from '../../utils/utility'
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { creaCondominio } from '../../actions/condominio';
import { getCartelleTuttiCondominio } from '../../actions/documento';

const CreaCondominio = ({ auth: { user, loading }, creaCondominio, alert, getCartelleTuttiCondominio, documento: { listDocumentiPubblici } }) => {

  const getDataPerLinkCondominio = () => {
    let data = new Date()
    let anno = data.getUTCFullYear()
    let mese = ("0" + (data.getMonth() + 1)).slice(-2)
    let giorno = data.getDate()
    return anno + "" + mese + "" + giorno
  }

  const getTimePerLinkCondominio = () => {
    let data = new Date()
    let ore = data.getHours();
    let minuti = (data.getMinutes() < 10 ? '0' : '') + data.getMinutes()
    return ore + "" + minuti
  }

  const initialState = {
    nome: "",
    indirizzo: "",
    urlImmagine: "",
    linkRegistrazione: "",
    nomeCartella: "",
    nomeBanca: "",
    iban: "",
    numeriUtili: [{ nome: "", numero: "" }],
    codicefiscale: "",
    periodoGestione: "",
    foglioMappale: "",
    immagineFoglioMappa: "",
    noteCondominio: "",
    documenti: []
  }

  const [condominioData, setCondominiData] = useState(initialState);
  const [cartelleCondominio, setCartelleCondominio] = useState([]);
  const [linkRegistrazioneComposto, setLinkRegistrazioneComposto] = useState({ parte1: "https://condomini.amministrazioni2t.com/registrazione/", parte2: "", parte3: getDataPerLinkCondominio(), parte4: getTimePerLinkCondominio() });
  const { parte1, parte2, parte3, parte4 } = linkRegistrazioneComposto;

  const { nome, indirizzo, urlImmagine, nomeCartella, nomeBanca, iban, numeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti } = condominioData;

  useEffect(() => {
    const size = alert.length;
    if (size > 0) {
      if (alert[size - 1].alertType === "success") {
        setCondominiData(initialState);
      }
    }
  }, [alert]);

  useEffect(() => {
    let array = []
    listDocumentiPubblici.map(el => {
      if (el[".tag"] === "folder") {
        let path = el.path_lower.substring(el.path_lower.lastIndexOf("/") + 1);
        array.push(path)
      }
      return true
    })
    setCartelleCondominio(array)
  }, [listDocumentiPubblici]);

  useEffect(() => {
    getCartelleTuttiCondominio()
  }, []);



  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
  }

  const onChange = e => setCondominiData({ ...condominioData, [e.target.name]: e.target.value });
  
  const onChangeLinkCondominio = e => setLinkRegistrazioneComposto({ ...linkRegistrazioneComposto, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    const cleanNumeriUtili = numeriUtili.filter(el => { return el.nome || el.telefono })
    creaCondominio({ nome, indirizzo, urlImmagine, linkRegistrazione: nome.toLocaleLowerCase() + "-" + parte2 + "-" + parte3 + "-" + parte4, nomeCartella, nomeBanca, iban, numeriUtili: cleanNumeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti });
  };

  const onChangeNumeriUtili = (e, i) => {
    let array = [...numeriUtili]
    array[i][e.target.name] = e.target.value
    setCondominiData({ ...condominioData, numeriUtili: array });
  }

  const onClickAggiungiRigaNumeroUtile = () => {
    let array = [...numeriUtili]
    array.push({ nome: "", numero: "" })
    setCondominiData({ ...condominioData, numeriUtili: array });
  }

  const onClickEliminaNumeroUtile = (i) => {
    let array = [...numeriUtili]
    array.splice(i, 1)
    setCondominiData({ ...condominioData, numeriUtili: array });
  }

  const onClickUploadImmagineCondominio = async (e) => {
    try {
      const data = await getUrlImmaginiCondominioUploaded(e)
      setCondominiData({ ...condominioData, urlImmagine: data });
      e.target.value = null;
    } catch (error) {
      console.log(error)
    }
  }

  const onClickUploadImmagineMappaCondominio = async (e) => {
    try {
        const data = await getUrlImmaginiMappaCondominioUploaded(e)
        setCondominiData({ ...condominioData, immagineFoglioMappa: data });
        e.target.value = null;
    } catch (error) {
      console.log(error)
    }
  }

  const onClickEliminaImmagineCondominio = () => {
    setCondominiData({ ...condominioData, urlImmagine: "" });
  }

  const onClickEliminaImmagineMappaCondominio = () => {
    setCondominiData({ ...condominioData, immagineFoglioMappa: "" });
  }

  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Crea condominio"}
        isFolder={false}
      />
      <div class="container flex-center">
        <div class="form-wrap wide">
          <div class="form-block w-form">
            <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" class="form" onSubmit={e => onSubmit(e)}>
              <h3 id="w-node-e7570c72-74ce-9da3-f85d-1baca43dd698-1ef19627" class="no-margin">Registrazione nuovo condominio</h3>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="nome" class="form-label">Nome <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="nome" data-name="nome" placeholder="Nome" id="nome" required="true" value={nome} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="codicefiscale" class="form-label">Codice fiscale <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="codicefiscale" data-name="codicefiscale" placeholder="Codice fiscale" id="codicefiscale" required="true" value={codicefiscale} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-e7570c72-74ce-9da3-f85d-1baca43dd69a-1ef19627" class="column">
                <label for="indirizzo" class="form-label">Indirizzo <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="indirizzo" data-name="indirizzo" placeholder="Indirizzo" required="true" id="indirizzo" value={indirizzo} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="iban" class="form-label">IBAN</label>
                <input type="text" class="input-field w-input" maxlength="256" name="iban" data-name="iban" placeholder="Iban" id="iban" value={iban} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="nomeBanca" class="form-label">Nome banca</label>
                <input type="text" class="input-field w-input" maxlength="256" name="nomeBanca" data-name="nomeBanca" placeholder="Nome banca" id="nomeBanca" value={nomeBanca} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="foglioMappale" class="form-label">Foglio mappale</label>
                <input type="text" class="input-field w-input" maxlength="256" name="foglioMappale" data-name="foglioMappale" placeholder="Foglio mappale" id="foglioMappale" value={foglioMappale} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="immagineFoglioMappa" class="form-label">Immagine mappa</label>
                {immagineFoglioMappa ?
                  <div class="immagine-condominio">
                    <a class="no-underline w-inline-block">
                      <div class="img-wrap">
                        <div class="adiacenti">
                          <a href={immagineFoglioMappa} target="_blank">
                            <img style={{ width: "30px" }} alt="foglio mappa" src={immagineFoglioMappa}></img></a>
                          <div class="img-del small">
                            <img src="/assets/media/delete.png" loading="lazy" sizes="20px" alt="" class="delete small" onClick={() => onClickEliminaImmagineMappaCondominio()} />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  :
                  <input type="file" className="input-field w-input" name="immagineFoglioMappa" data-name="immagineFoglioMappa" onChange={(e) => onClickUploadImmagineMappaCondominio(e)} />
                }              
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="urlImmagine" class="form-label">Immagine condominio</label>
                {urlImmagine ?
                  <div class="immagine-condominio">
                    <a class="no-underline w-inline-block">
                      <div class="img-wrap">
                        <div class="adiacenti">
                          <a href={urlImmagine} target="_blank">
                            <img style={{ width: "30px" }} alt="immagine condominio" src={urlImmagine}></img></a>
                          <div class="img-del small">
                            <img src="/assets/media/delete.png" loading="lazy" sizes="20px" alt="" class="delete small" onClick={() => onClickEliminaImmagineCondominio()} />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  :
                  <input type="file" className="input-field w-input" name="urlImmagine" data-name="urlImmagine" onChange={(e) => onClickUploadImmagineCondominio(e)} />
                }
              </div>
              <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                <label for="periodoGestione" class="form-label">Periodo di gestione</label>
                <input type="text" class="input-field w-input" maxlength="256" name="periodoGestione" data-name="periodoGestione" placeholder="Periodo di gestione" id="periodoGestione" value={periodoGestione} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-e7570c72-74ce-9da3-f85d-1baca43dd69a-1ef19627" class="column">
                <label for="noteCondominio" class="form-label">Note</label>
                <textarea type="text" class="input-field w-input" maxlength="256" name="noteCondominio" data-name="noteCondominio" placeholder="Note" id="noteCondominio" value={noteCondominio} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-e7570c72-74ce-9da3-f85d-1baca43dd69a-1ef19627" class="column">
                <label for="linkRegistrazione" class="form-label">Link registrazione <span class="required">*</span></label>
                <div class="adiacenti link-condominio">
                  <input type="text" class="input-field w-input w1" maxlength="256" name="parte1" data-name="parte1" id="parte1" required="true" value={parte1 + nome.toLocaleLowerCase()} disabled />
                  <input type="text" class="input-field w-input w2" maxlength="256" name="parte2" data-name="parte2" placeholder="link" id="parte2" required="true" value={parte2} onChange={e => onChangeLinkCondominio(e)} />
                  <input type="text" class="input-field w-input w3" maxlength="256" name="parte3" data-name="parte3" id="parte3" required="true" value={parte3} disabled />
                  <input type="text" class="input-field w-input w4" maxlength="256" name="parte4" data-name="parte4" id="parte4" required="true" value={parte4} disabled />
                </div>
              </div>
              <div id="w-node-_8f3a525e-f3a8-a69e-285d-23863f4bcca5-347debdf" class="column">
                <label for="Industry-select" class="form-label">Cartella Dropbox <span class="required">*</span></label>
                <select id="Industry-select" name="nomeCartella" data-name="Industry select" required="true" class="select-field w-select" onChange={e => onChange(e)}>
                  {cartelleCondominio.map(el => (
                    <option selected={el === nomeCartella} value={el}>{el}</option>
                  ))}
                </select>
              </div>
              <div id="w-node-c2bc9a2c-906b-af5e-88f5-289f2407ad3c-347debdf" class="separator">
                <p class="subtitle center">Numeri utili</p>
              </div>
              {numeriUtili.map((el, i) => (
                <div id="w-node-_8f3a525e-f3a8-a69e-285d-23863f4bcca5-347debdf" class="adiacenti low">
                  <div id="w-node-a95c86cb-ef2e-3097-e44f-af119b010aa8-1ef19627" class="column w100">
                    <label for="nome" class="form-label">Nome</label>
                    <input type="text" class="input-field w-input" maxlength="256" name="nome" data-name="nome" placeholder="Nome" id="nome" value={el.nome} onChange={e => onChangeNumeriUtili(e, i)} />
                  </div>
                  <div class="column w100">
                    <label for="telefono" class="form-label">Telefono</label>
                    <input type="text" class="input-field w-input" maxlength="256" name="telefono" data-name="telefono" placeholder="Telefono" id="telefono" value={el.telefono} onChange={e => onChangeNumeriUtili(e, i)} />
                  </div>
                  <div class="img-del">
                    <img src="/assets/media/delete.png" loading="lazy" alt="" class="delete" onClick={() => { onClickEliminaNumeroUtile(i) }} />
                  </div>
                </div>
              ))}
              <input value="+" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button action" onClick={() => { onClickAggiungiRigaNumeroUtile() }} />
              <input type="submit" value="Salva" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};

CreaCondominio.propTypes = {
  auth: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  documento: PropTypes.object.isRequired,
  creaCondominio: PropTypes.func.isRequired,
  getCartelleTuttiCondominio: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  alert: state.alert,
  documento: state.documento
});

export default connect(mapStateToProps, { creaCondominio, getCartelleTuttiCondominio })(CreaCondominio);