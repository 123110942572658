import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {apriFileFromBlob} from '../../utils/utility'
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import DocumentoElement from '../documenti/DocumentoElement';
import CartellaElement from '../documenti/CartellaElement';
import { getListDocumentiPrivati, getLinkDocumento, clearlinkDocumento } from '../../actions/documento';
import { getCondomino } from '../../actions/condomino';

const DocumentiPrivatiAdmin = ({ auth: { user, loading }, documento: { listDocumentiPrivati, documento }, getListDocumentiPrivati, getLinkDocumento, clearlinkDocumento, condomino: { condominoUser }, getCondomino }) => {

  let { idCondomino, idCondominio } = useParams();

  const initialState = {
    urlDocumentiPrivati: ''
  }

  const [condominoData, setCondominoData] = useState(initialState);
  const [elementiFile, setElementiFile] = useState([]);
  const [elementiFolder, setElementiFolder] = useState([]);
  const [currentPath, setCurrentPath] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState("");

  
  useEffect(() => {
    getListDocumentiPrivati({ idCondomino: idCondomino, idCondominio: idCondominio, path: currentPath })
    getCondomino({idCondomino: idCondomino})
  }, [idCondomino, idCondominio]);

  useEffect(() => {
    setCondominoData({
      urlDocumentiPrivati: loading || !condominoUser ? '' : condominoUser.urlDocumentiPrivati,
    })
  }, [condominoUser]);

  const { urlDocumentiPrivati } = condominoData;


  useEffect(() => {
    return () => {
      setElementiFolder([])
      setElementiFile([])
      clearlinkDocumento()
    };
  }, []);


  useEffect(() => {
    let arrayFolder = [];
    let arrayFile = [];
    if (listDocumentiPrivati.length > 0) {
      let listFiletered = listDocumentiPrivati.filter(el => el.name && !el.name.includes('+'))
      listFiletered.map(el => {
        if(el[".tag"] === "folder"){
          arrayFolder.push(el)
        }
        if(el[".tag"] === "file"){
          arrayFile.push(el)
        }
      })
    }
    arrayFolder = arrayFolder.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)); 
    arrayFile = arrayFile.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)); 
    setElementiFolder(arrayFolder)
    setElementiFile(arrayFile)
  }, [listDocumentiPrivati]);

  useEffect(() => {
    apriFileFromBlob({documento: documento})
  }, [documento]);

  const onClickGetLinkDocumento = (path) => {
    getLinkDocumento({ path: path })
  }
  const onClickListFolder = (path) => {
    let str = path.substring(path.indexOf(urlDocumentiPrivati) + urlDocumentiPrivati.length);
    setBreadcrumbs(breadcrumbs + generaBreadcrumbs(str))
    setCurrentPath(str)
    getListDocumentiPrivati({ idCondominio: idCondominio, idCondomino: idCondomino, path: str })
  }

  const onClickBackListFolder = () => {
    let path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    setCurrentPath(path)
    setBreadcrumbs(path ? generaBreadcrumbs(path) : path)
    getListDocumentiPrivati({idCondominio: idCondominio, idCondomino: idCondomino, path: path })
  }

  const generaBreadcrumbs = (path) => {
    let index = path.lastIndexOf("-")
    let breadcrumb = "";
    if(index >= 0){
      breadcrumb = path.slice(path.lastIndexOf("-")+1);
    }else{
      index = path.lastIndexOf("/")
      if(index >= 0){
        breadcrumb = path.slice(path.lastIndexOf("/")+1);
      }
    }
    return '<span class="text-span-2"> > </span>' + breadcrumb;
  }



  return (loading || urlDocumentiPrivati.length <= 0 ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={`Documenti privati ${breadcrumbs}`}
        isFolder={true}
        onClickBackListFolder={onClickBackListFolder}
      />
      <div class="w100">
        <div class="margin mt0">
          <div class="list-categorie">
            {elementiFolder.length > 0 ?
              elementiFolder.map(el => (
                <CartellaElement
                  onClickListFolder={onClickListFolder}
                  nomeCartella={el.name}
                  urlCartella={el.path_lower}
                />
              ))
              :
              <span></span>
            }
          </div>
        </div>
      </div>
      <div class="w100">
        <div class="margin mt0">
          <div class="colonne">
            <div class="w100">
              {elementiFile.length > 0 ?
                <div class="container-box zoom">
                  <div class="lista-file zoom">
                    {elementiFile.map(el => (
                      <DocumentoElement
                        onClickGetLinkDocumento={onClickGetLinkDocumento}
                        nomeFile={el.name}
                        urlFile={el.path_lower}
                        isPdf={el.name.endsWith(".pdf")}
                        isImg={el.name.endsWith(".png") || el.name.endsWith(".jpg") || el.name.endsWith(".jpeg")}
                        privato={false}
                      />
                    ))}
                  </div>
                </div>
                : elementiFolder.length > 0 ?
                  <span></span>
                  :
                  <p>Nessun documento trovato.</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

DocumentiPrivatiAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  documento: PropTypes.object.isRequired,
  condomino: PropTypes.object.isRequired,
  getListDocumentiPrivati: PropTypes.func.isRequired,
  getLinkDocumento: PropTypes.func.isRequired,
  clearlinkDocumento: PropTypes.func.isRequired,
  getCondomino: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  documento: state.documento,
  condomino: state.condomino
});

export default connect(mapStateToProps, { getListDocumentiPrivati, getLinkDocumento, clearlinkDocumento, getCondomino })(DocumentiPrivatiAdmin);