import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { getCondominiNonAccettati, accettaCondomini } from '../../actions/condomino';
import { getCondomini } from '../../actions/condominio';

const AccettaCondomino = ({ auth: { user, loading }, condomino: { condominiUser }, condominio: { condomini }, getCondominiNonAccettati, getCondomini, accettaCondomini }) => {

  const [condominoData, setCondominoData] = useState([]);
  const [condominioData, setCondominioData] = useState([]);
  const [testoCerca, setTestoCerca] = useState("");
  const [condominioSelected, setCondominioSelected] = useState(null);
  const [condominoDaAccettare, setCondominoDaAccettare] = useState([]);
  const [selezionaTuttiChecked, setSelezionaTuttiChecked] = useState(false);
  
  
  useEffect(() => {
    getCondominiNonAccettati()
    getCondomini()
  }, []);

  useEffect(() => {
    setCondominoData(condominiUser)
  }, [condominiUser]);

  useEffect(() => {
    setCondominioData(condomini)
  }, [condomini]);

  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
  }

const onChangeFiltraCondominoByCondominio = (idCondominio) => {
  let arrayCondomino = [...condominiUser]
  if(idCondominio){
    setCondominioSelected(idCondominio)
    arrayCondomino = arrayCondomino.filter(el => (el.condominio._id === idCondominio))
  }
  if(testoCerca){
    arrayCondomino = arrayCondomino.filter(el => (el.cognome.toLowerCase().includes(testoCerca.toLowerCase()) || el.nome.toLowerCase().includes(testoCerca.toLowerCase())))
  }
  setCondominoData(arrayCondomino)
}

const onChangeFiltraCondominoByCognome = (testo) => {
  setTestoCerca(testo)
  let arrayCondomino = [...condominiUser]
  if(testo){
    arrayCondomino = arrayCondomino.filter(el => (el.cognome.toLowerCase().includes(testo.toLowerCase()) || el.nome.toLowerCase().includes(testo.toLowerCase())))
  }
  if(condominioSelected){
    arrayCondomino = arrayCondomino.filter(el => (el.condominio._id === condominioSelected))
  }
  setCondominoData(arrayCondomino)
}

const onChangeCheckboxCondomini = (idCondomino) => {
  let arrayCondomino = [...condominoDaAccettare]
  if(arrayCondomino.includes(idCondomino)){
    arrayCondomino = arrayCondomino.filter(el => (el !== idCondomino))
  }else{
    arrayCondomino.push(idCondomino)
  }
  setCondominoDaAccettare(arrayCondomino)
}

const onChangeSelezionaTuttiCheckboxCondomini = () => {
  let arrayCondomino = [...condominoDaAccettare]
  setSelezionaTuttiChecked(!selezionaTuttiChecked)
  if(selezionaTuttiChecked){
    arrayCondomino = []
  }else{
    condominiUser.map(el => { arrayCondomino.push(el._id) })
  }
  setCondominoDaAccettare(arrayCondomino)
}

const onClickAccettaCondomini = () => {
  accettaCondomini({condomini: condominoDaAccettare})
}

const onClickCopyText = (e, str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
};

  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Accetta nuovo condomino"}
        isFolder={false}
      />
      <div class="w100">
      <div class="margin mt0">
        <div class="esterni gestione">
          <form action="/search" class="search w-form">
            <input type="search" class="search-input w-input" maxlength="256" name="query" placeholder="Cerca condomino" id="search-2" value={testoCerca} onChange={(e) => onChangeFiltraCondominoByCognome(e.target.value)}/>
            <input disabled type="submit" value="Cerca" class="search-btn w-button"/>
          </form>
          <a href="/crea-condomino" class="button-small w-button">Crea nuovo condomino</a>
        </div>
        <div class="colonne">
          <div class="w100">
            <div class="list-gestione">
              <div class="w50">
                <div class="w100">
                  <div class="form-block w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form">
                      <div id="w-node-_51302091-a96a-15b3-ad1c-2dcded061382-5937f26f" class="column">
                        <label for="Industry-select" class="form-label">Filtra per condominio</label>
                        <select id="Industry-select" name="Industry-select" data-name="Industry select" class="select-field w-select" onChange={(e) => {onChangeFiltraCondominoByCondominio(e.target.value)}}>
                          <option selected={condominioSelected === ""} value="">Seleziona condominio</option>
                          {condominioData.map(el => (
                            <option selected={condominioSelected === el._id} value={el._id}>{el.nome}</option>
                            ))}
                        </select></div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="wide-responsive">
                <div class="w-layout-grid richieste-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                  <input type="checkbox" id="seleziona-tutti" data-name="seleziona-tutti" checked={selezionaTuttiChecked} onChange={() => onChangeSelezionaTuttiCheckboxCondomini()}/>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <p>Nome Cognome</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4a-5937f26f">
                    <p>Nome condominio</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>1</sup></p>
                  </div> 
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>2</sup></p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-5937f26f">
                    <p>Telefono</p>
                  </div>
                </div>
                <div>
                  {condominoData.map((el) => (
                    <div class="w-layout-grid richieste-utente-grid" onClick={() => onChangeCheckboxCondomini(el._id)}>
                      <a id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-5937f26f" class="item-grid w-inline-block">
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-5937f26f">
                      <input type="checkbox" id={el._id} data-name="Pricing Plan" checked={condominoDaAccettare.includes(el._id)} onChange={() => onChangeCheckboxCondomini(el._id)}/>
                      </div>
                      </a>
                    <a id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-5937f26f" href="#" class="item-grid w-inline-block">
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-5937f26f">
                        <p>{el.nome} {el.cognome}</p>
                      </div>
                    </a>
                    <a id="w-node-_4998b544-e2a8-52f7-0b3f-4ed35686f90a-5937f26f" href="#" class="item-grid w-inline-block">
                      <div>
                        <p>{el.condominio.nome}</p>
                      </div>
                    </a>
                    {el.email1 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {onClickCopyText(e, el.email1)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                        <p>{el.email1}</p>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                    {el.email2 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {onClickCopyText(e, el.email2)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                        <p>{el.email2}</p>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                    {el.telefono1 ?
                    <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-5937f26f" class="item-grid w-inline-block" onClick={(e) => {onClickCopyText(e, el.telefono1)}}>
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-5937f26f" class="adiacenti">
                        <img src="/assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-5937f26f" alt="" class="icon"/>
                        <p>{el.telefono1}</p>
                      </div>
                    </a>
                    :
                    <span></span>
                    }
                  </div>
                  ))}
                </div>
                <div class="w-layout-grid richieste-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                  <input type="checkbox" id="seleziona-tutti" data-name="seleziona-tutti" checked={selezionaTuttiChecked} onChange={() => onChangeSelezionaTuttiCheckboxCondomini()}/>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <p>Nome Cognome</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4a-5937f26f">
                    <p>Nome condominio</p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>1</sup></p>
                  </div> 
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-5937f26f">
                    <p>Email<sup>2</sup></p>
                  </div>
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-5937f26f">
                    <p>Telefono</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="esterni gestione">
          <span></span>
          <a onClick={() => (onClickAccettaCondomini())} class="button-small w-button">Accetta selezionati</a>
        </div>
      </div>
    </div>
    </div>
  )
};

AccettaCondomino.propTypes = {
  auth: PropTypes.object.isRequired,
  condomino: PropTypes.object.isRequired,
  condominio: PropTypes.object.isRequired,
  getCondominiNonAccettati: PropTypes.func.isRequired,
  getCondomini:  PropTypes.func.isRequired,
  accettaCondomini: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  condomino: state.condomino,
  condominio: state.condominio
});

export default connect(mapStateToProps, { getCondominiNonAccettati, getCondomini, accettaCondomini })(AccettaCondomino);