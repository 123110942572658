import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { getNotifiche, creaNotifica, eliminaNotifiche } from '../../actions/notifica';
import { getCondomini } from '../../actions/condominio';
import { getCondominiCondominio } from '../../actions/condomino';

const GestioneNotifiche = ({ auth: { user, loading }, notifica: { notifiche }, getNotifiche, alert, getCondomini, condominio: { condomini }, getCondominiCondominio, condomino: {condominiUser}, creaNotifica, eliminaNotifiche }) => {

  const [notificheData, setNotificheData] = useState([]);
  const [nuovaNotifica, setNuovaNotifica] = useState({
    idCondomino: "",
    messaggio: ""
  });

  const { idCondomino, messaggio } = nuovaNotifica;

  const [condominioSelected, setCondominioSelected] = useState(null);
  const [condominioData, setCondominioData] = useState([]);

  const [condominoSelected, setCondominoSelected] = useState('');
  const [condominoData, setCondominoData] = useState([]);

  const [condominioFilterSelected, setCondominioFilterSelected] = useState('');

  const [notificheSelected, setNotificheSelected] = useState([]);
  const [selezionaTuttiChecked, setSelezionaTuttiChecked] = useState(false);

  const [testoCerca, setTestoCerca] = useState([]);

  useEffect(() => {
    getNotifiche({ idCondominio: condominioSelected })
    getCondomini()
  }, []);

  useEffect(() => {
    setCondominioData(condomini)
  }, [condomini]);

  useEffect(() => {
    setCondominoData(condominiUser)
  }, [condominiUser]);

  useEffect(() => {
    setNotificheData(notifiche)
  }, [notifiche]);
  
  useEffect(() => {
    if(condominioSelected){
      getCondominiCondominio({idCondominio: condominioSelected})
    }
  }, [condominioSelected]);

  useEffect(() => {
    const size = alert.length;
    if (size > 0) {
      if (alert[size - 1].alertType === "success") {
        setNuovaNotifica({
          idCondomino: "",
          messaggio: ""
        })
      }
    }
  }, [alert]);



  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
  }


  const onChange = e => setNuovaNotifica({ ...nuovaNotifica, [e.target.name]: e.target.value });
  const onChangeCondominio = (id) => setCondominioSelected(id);
  const onChangeCondomino = (id) => setCondominoSelected(id);
  const onChangeFilterCondominio = (id) => {
    let arrayNotifiche = [...notifiche]
    if(id !== 'tutti'){
      arrayNotifiche = arrayNotifiche.filter(el => (el.condomino.condominio._id ===  id))
    }
    setNotificheData(arrayNotifiche)
    setCondominioFilterSelected(id)
  };
  
  const onSubmit = async e => {
    e.preventDefault();
    creaNotifica({ idCondomino: condominoSelected, idCondominio: condominioSelected, messaggio: messaggio });
  };

  const onChangeCheckboxNotifica = (idNotifica) => {
    let arrayNotifiche = [...notificheSelected]
    if(arrayNotifiche.includes(idNotifica)){
      arrayNotifiche = arrayNotifiche.filter(el => (el !== idNotifica))
    }else{
      arrayNotifiche.push(idNotifica)
    }
    setNotificheSelected(arrayNotifiche)
  }

  const onChangeSelezionaTuttiCheckboxNotifiche = () => {
    let arraynotifiche = [...notificheSelected]
    setSelezionaTuttiChecked(!selezionaTuttiChecked)
    if(selezionaTuttiChecked){
      arraynotifiche = []
    }else{
      notifiche.map(el => { arraynotifiche.push(el._id) })
    }
    setNotificheSelected(arraynotifiche)
  }

  const onClickCopyText = (e, str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(str);
    return Promise.reject('The Clipboard API is not available.');
  };

  const onChangeFiltraNotifiche = (testo) => {
    setTestoCerca(testo)
    let arrayNotifiche = [...notifiche]
    if(testo){
      arrayNotifiche = arrayNotifiche.filter(el => (el.condomino.nome.toLowerCase().includes(testo.toLowerCase()) || el.condomino.cognome.toLowerCase().includes(testo.toLowerCase())))
    }
    setNotificheData(arrayNotifiche)
  }

  const onClickEliminaNotifiche = (e) => {
    e.preventDefault()
    eliminaNotifiche({arrayNotifiche: notificheSelected})
  }

  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Gestione notifiche"}
        isFolder={false}
      />
      <div class="w100">
        <div class="margin mt0">
          <div class="colonne">
            <div class="w100">
              <div class="form-wrap wide mt0">
                <div class="form-block w-form">
                  <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" onSubmit={e => onSubmit(e)} class="form">
                    <h3 id="w-node-a90294b5-464b-9bbb-81f2-33bae37026d8-d15f29e1" class="no-margin mt0">Crea nuova notifica</h3>
                    <div id="w-node-a90294b5-464b-9bbb-81f2-33bae37026da-d15f29e1" class="column">
                      <label for="Nome" class="form-label">Messaggio</label>
                      <textarea class="input-field w-input textarea" maxlength="5000" name="messaggio" data-name="messaggio" placeholder="Messaggio" id="messaggio" value={messaggio} onChange={e => onChange(e)} />
                    </div>
                    <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                    <label for="Nome" class="form-label">Condominio</label>
                      <select id="Industry-select" name="Industry-select" data-name="Industry select" class="select-field w-select" required="true" onChange={(e) => { onChangeCondominio(e.target.value) }}>
                        <option selected={condominioSelected === ""} value="">Seleziona condominio</option>
                        {condominioData.map(el => (
                          <option selected={condominioSelected === el._id} value={el._id}>{el.nome}</option>
                        ))}
                      </select>
                    </div>
                    <div id="w-node-d856d2e4-08c5-88c1-6f58-7170b4b93772-347debdf" class="column">
                    <label for="Nome" class="form-label">Condomino</label>
                      <select id="Industry-select" name="Industry-select" data-name="Industry select" class="select-field w-select" required="true" onChange={(e) => { onChangeCondomino(e.target.value) }}>
                        <option selected={condominioFilterSelected === ""} value="" disabled >Seleziona condomino</option>
                        <option selected={condominioFilterSelected === "tutti"} value="tutti" >Tutti</option>
                        {condominoData.map(el => (
                          <option selected={condominoSelected === el._id} value={el._id}>{el.cognome} {el.nome}</option>
                        ))}
                      </select>
                    </div>
                    <input type="submit" value="Crea notifica" data-wait="Un attimo..." class="submit-button w-button" />
                  </form>
                </div>
              </div>
            </div>
            <div class="w100">
   <div class="margin mt0">
      <div class="esterni gestione">
         <form action="/search" class="search w-form">
         <input type="search" class="search-input w-input" maxlength="256" name="query" placeholder="Cerca condomino" id="search-2" value={testoCerca} onChange={(e) => onChangeFiltraNotifiche(e.target.value)} />
         <input disabled  type="submit" value="Cerca" class="search-btn w-button" />
            </form>
      </div>
      <div class="colonne">
         <div class="w100">
            <div class="list-gestione m10">
               <div class="w50">
                  <div class="w100">
                     <div class="form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" aria-label="Email Form">
                           <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f5c-d2e50384" class="column">
                              <label for="Industry-select-2" class="form-label">Filtra per condominio</label>
                              <select id="Industry-select" name="Industry-select" data-name="Industry select" class="select-field w-select" onChange={(e) => { onChangeFilterCondominio(e.target.value) }}>
                                <option selected={condominioFilterSelected === ""} value="tutti" required="true">Tutti</option>
                                {condominioData.map(el => (
                                <option selected={condominioFilterSelected === el._id} value={el._id}>{el.nome}</option>
                                ))}
                              </select>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <div class="wide-responsive">
                  <div class="w-layout-grid notifiche-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <input type="checkbox" id="seleziona-tutti" data-name="seleziona-tutti" checked={selezionaTuttiChecked} onChange={() => onChangeSelezionaTuttiCheckboxNotifiche()}/>
                  </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f6b-d2e50384">
                        <p>Messaggio</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f6e-d2e50384">
                        <p>Condomino</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f71-d2e50384">
                        <p>Condominio</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f74-d2e50384">
                        <p>Email<sup>1</sup></p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f74-d2e50384">
                        <p>Visualizzata</p>
                     </div>
                  </div>
                  {notificheData.map((el) => (
                    <div>
                     <div class="w-layout-grid notifiche-utente-grid">
                     <a id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-5937f26f" class="item-grid w-inline-block">
                      <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-5937f26f">
                      <input type="checkbox" id={el._id} data-name="Pricing Plan" checked={notificheSelected.includes(el._id)} onChange={() => onChangeCheckboxNotifica(el._id)}/>
                      </div>
                      </a>
                        <a id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f7c-d2e50384" href="#" class="item-grid w-inline-block">
                           <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f7d-d2e50384">
                              <p>{el.messaggio}</p>
                           </div>
                        </a>
                        <a id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f80-d2e50384" href="#" class="item-grid w-inline-block">
                        <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f79-d2e50384">
                              <p>{el.condomino.cognome} {el.condomino.nome}</p>
                           </div>
                        </a>
                        <a id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f84-d2e50384" href="#" class="item-grid w-inline-block">
                        <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f79-d2e50384">
                              <p>{el.condomino.condominio.nome}</p>
                           </div>
                        </a>
                        <a id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f89-d2e50384" onClick={(e) => {onClickCopyText(e, el.email1)}} class="item-grid w-inline-block">
                           <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f8a-d2e50384" class="adiacenti">
                              <img src="https://uploads-ssl.webflow.com/63a5c016f8cc91d41d33d562/63b588a91fcdf6f088c85f1b_copy-regular.svg" loading="lazy" id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f8b-d2e50384" alt="" class="icon"/>
                              <p>{el.condomino.email1}</p>
                           </div>
                        </a>
                        <a id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f89-d2e50384" onClick={(e) => {onClickCopyText(e, el.email1)}} class="item-grid w-inline-block">
                           <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f8a-d2e50384" class="adiacenti">
                              <p>{el.letta ? 'si ✅': 'no ❌'}</p>
                           </div>
                        </a>
                     </div>
                  </div>
                  ))}
                  <div class="w-layout-grid notifiche-grid-indice">
                  <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-5937f26f">
                    <input type="checkbox" id="seleziona-tutti" data-name="seleziona-tutti" checked={selezionaTuttiChecked} onChange={() => onChangeSelezionaTuttiCheckboxNotifiche()}/>
                  </div>
                  <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f6b-d2e50384">
                        <p>Messaggio</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f6e-d2e50384">
                        <p>Condomino</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f71-d2e50384">
                        <p>Condominio</p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f74-d2e50384">
                        <p>Email<sup>1</sup></p>
                     </div>
                     <div id="w-node-_02d82921-26f3-ad4a-f75d-8a566f541f74-d2e50384">
                        <p>Visualizzata</p>
                     </div>
                  </div>
               </div>
               <div class="w50">
               <input value="Elimina notifiche" data-wait="Un attimo..." class="submit-button w-button" onClick={(e) => onClickEliminaNotifiche(e)}/>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
          </div>
        </div>
      </div>
    </div>
  )
};

GestioneNotifiche.propTypes = {
  auth: PropTypes.object.isRequired,
  notifica: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  condomino: PropTypes.object.isRequired,
  condominio: PropTypes.object.isRequired,
  getNotifiche: PropTypes.func.isRequired,
  getCondomini: PropTypes.func.isRequired,
  getCondominiCondominio: PropTypes.func.isRequired,
  creaNotifica: PropTypes.func.isRequired,
  eliminaNotifiche: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  notifica: state.notifica,
  alert: state.alert,
  condomino: state.condomino,
  condominio: state.condominio
});

export default connect(mapStateToProps, { getNotifiche, getCondomini, getCondominiCondominio, creaNotifica, eliminaNotifiche })(GestioneNotifiche);