import axios from 'axios';
import { setAlert } from './alert';
import { GET_LIST_DOCUMENTI, GET_LIST_DOCUMENTI_GENERALI, GET_LIST_DOCUMENTI_GESTIONI, GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA, GET_LIST_DOCUMENTI_DOCUMENTI, GET_LIST_DOCUMENTI_PRIVATI, GET_LINK_DOCUMENTO, CLEAR_LINK_DOCUMENTO, GET_CARTELLE_TUTTI_CONDOMINIO, GET_CARTELLE_AREA_PRIVATA_CONDOMINIO, DOCUMENTO_ERROR } from './types';

export const getListDocumenti = ({ path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ path });
    try{
        const res = await axios.post('/api/documento/getListDocumenti', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const getListDocumentiPubblici = ({ idCondominio, path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, path });
    try{
        const res = await axios.post('/api/documento/getListDocumentiPubblici', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI_GENERALI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const getListDocumentiGestioni = ({ idCondominio, path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, path });
    try{
        const res = await axios.post('/api/documento/getListDocumentiGestioni', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI_GESTIONI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const getListDocumentiVerbaliAssemblea = ({ idCondominio, path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, path });
    try{
        const res = await axios.post('/api/documento/getListDocumentiVerbaliAssemblea', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const getListDocumentiDocumenti = ({ idCondominio, path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, path });
    try{
        const res = await axios.post('/api/documento/getListDocumentiDocumenti', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI_DOCUMENTI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}


export const getListDocumentiPrivati = ({ idCondominio, idCondomino, path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, idCondomino, path });
    try{
        const res = await axios.post('/api/documento/getListDocumentiPrivati', body, config);
        dispatch({
            type: GET_LIST_DOCUMENTI_PRIVATI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const getLinkDocumento = ({ path }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ path });
    try{
        const res = await axios.post('/api/documento/getLinkDocumento', body, config);
        
        dispatch({
            type: GET_LINK_DOCUMENTO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
};

export const getCartelleTuttiCondominio = () => async dispatch => {
    try{
        const res = await axios.get('/api/documento/getCartelleTuttiCondominio');
        
        dispatch({
            type: GET_CARTELLE_TUTTI_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
};


export const getCartelleAreaPrivataCondominio = ({ idCondominio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio });
    try{
        const res = await axios.post('/api/documento/getCartelleAreaPrivataCondominio', body, config);
        dispatch({
            type: GET_CARTELLE_AREA_PRIVATA_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR
        });
    }
}

export const clearlinkDocumento = () => dispatch => {
    dispatch({ type: CLEAR_LINK_DOCUMENTO });  
};