export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';


export const REGISTER_FAIL = 'REGISTER_FAIL';


export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const MODIFICA_PROFILO_CONDOMINO = 'MODIFICA_PROFILO_CONDOMINO';
export const REGISTRA_NUOVO_CONDOMINO = 'REGISTRA_NUOVO_CONDOMINO';
export const RECUPERA_PASSWORD = 'RECUPERA_PASSWORD';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const MODIFICA_PASSWORD = 'MODIFICA_PASSWORD';
export const PROFILO_ERROR = 'PROFILO_ERROR';




export const GET_LIST_DOCUMENTI = 'GET_LIST_DOCUMENTI';
export const GET_LIST_DOCUMENTI_GENERALI = 'GET_LIST_DOCUMENTI_GENERALI';
export const GET_LIST_DOCUMENTI_GESTIONI = 'GET_LIST_DOCUMENTI_GESTIONI';
export const GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA = 'GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA';
export const GET_LIST_DOCUMENTI_DOCUMENTI = 'GET_LIST_DOCUMENTI_DOCUMENTI';
export const GET_LIST_DOCUMENTI_PRIVATI = 'GET_LIST_DOCUMENTI_PRIVATI';
export const GET_LINK_DOCUMENTO = 'GET_LINK_DOCUMENTO';
export const CLEAR_LINK_DOCUMENTO = 'CLEAR_LINK_DOCUMENTO';
export const GET_CARTELLE_TUTTI_CONDOMINIO = 'GET_CARTELLE_TUTTI_CONDOMINIO';
export const GET_CARTELLE_AREA_PRIVATA_CONDOMINIO = 'GET_CARTELLE_AREA_PRIVATA_CONDOMINIO'; 
export const DOCUMENTO_ERROR = 'DOCUMENTO_ERROR';


export const GET_CONDOMINO = 'GET_CONDOMINO';
export const GET_TUTTI_CONDOMINO = 'GET_TUTTI_CONDOMINO';
export const CREA_CONDOMINO = 'CREA_CONDOMINO';
export const GET_CONDOMINI_CONDOMINIO = 'GET_CONDOMINI_CONDOMINIO';
export const GET_CATEGORIE_CONDOMINO = 'GET_CATEGORIE_CONDOMINO';
export const CREA_CATEGORIA_CONDOMINO = 'CREA_CATEGORIA_CONDOMINO';
export const GET_CONDOMINI_ACCETTATI = 'GET_CONDOMINI_ACCETTATI';
export const GET_CONDOMINI_NON_ACCETTATI = 'GET_CONDOMINI_NON_ACCETTATI';
export const ACCETTA_CONDOMINI = 'ACCETTA_CONDOMINI';
export const MODIFICA_CONDOMINO = 'MODIFICA_CONDOMINO';
export const GET_CONDOMINI_CATEGORIA = 'GET_CONDOMINI_CATEGORIA';
export const CONDOMINO_ERROR = 'CONDOMINO_ERROR'; 


export const GET_CONDOMINIO = 'GET_CONDOMINIO'; 
export const GET_CONDOMINI = 'GET_CONDOMINI'; 
export const CREA_CONDOMINIO = 'CREA_CONDOMINIO'; 
export const ATTIVA_DISATTIVA_CONDOMINIO = 'ATTIVA_DISATTIVA_CONDOMINIO'; 
export const MODIFICA_CONDOMINIO = 'MODIFICA_CONDOMINIO'; 
export const CHECK_LINK_REGISTRAZIONE_CONDOMINIO = 'CHECK_LINK_REGISTRAZIONE_CONDOMINIO'; 
export const GET_CONDOMINI_CONSIGLIERI = 'GET_CONDOMINI_CONSIGLIERI'; 
export const CONDOMINIO_ERROR = 'CONDOMINIO_ERROR'; 

export const GET_NOTIFICHE = 'GET_NOTIFICHE'; 
export const CREA_NOTIFICA = 'CREA_NOTIFICA'; 
export const GET_NOTIFICA_CONDOMINO = 'GET_NOTIFICA_CONDOMINO'; 
export const LEGGI_NOTIFICA = 'LEGGI_NOTIFICA'; 
export const ELIMINA_NOTIFICA = 'ELIMINA_NOTIFICA'; 
export const NOTIFICA_ERROR = 'NOTIFICA_ERROR';

export const GET_LOGO = 'GET_LOGO';
export const GET_IMPOSTAZIONI = 'GET_IMPOSTAZIONI';
export const MODIFCA_IMPOSTAZIONI = 'MODIFCA_IMPOSTAZIONI';
export const IMPOSTAZIONI_ERROR = 'IMPOSTAZIONI_ERROR';