import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import { modificaProfiloCondomino } from '../../actions/auth';

const ModificaProfilo = ({ auth: { user, loading }, modificaProfiloCondomino }) => {

  const [utente, setUtente] = useState({
    nome: '',
    cognome: '',
    codicefiscale:  '',
    email1:  '',
    email2:  '',
    luogoNascita:  '',
    dataNascita:  '',
    telefono1:  '',
    telefono2:  ''
  });

  useEffect(() => {
    setUtente({
      idCondomino: loading || !user ? '' : user._id,
      nome: loading || !user ? '' : user.nome,
      cognome: loading || !user ? '' : user.cognome,
      codicefiscale: loading || !user ? '' : user.codicefiscale,
      email1: loading || !user ? '' : user.email1,
      email2: loading || !user ? '' : user.email2,
      luogoNascita: loading || !user ? '' : user.luogoNascita,
      dataNascita: loading || !user ? '' : user.dataNascita,
      telefono1: loading || !user ? '' : user.telefono1,
      telefono2: loading || !user ? '' : user.telefono2,
      nomeCondominio: loading || !user || !user.condominio ? '' : user.condominio.nome,
      urlImmagine: loading || !user || !user.condominio ? '' : user.condominio.urlImmagine
    })
  }, [user, loading]);

  const { idCondomino, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, nomeCondominio, urlImmagine } = utente;

  const onChange = e => setUtente({ ...utente, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    modificaProfiloCondomino({ idCondomino, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2 });
};

  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar 
        labelPagina={"Modifica profilo"}
        isFolder={false}
     />
      <div class="container flex-center">
        <div class="form-wrap wide">
          <div class="center">
            <h3 class="no-margin">Condominio {nomeCondominio}</h3>
            {urlImmagine ?
            <div class="div-block">
              <img src={urlImmagine} loading="lazy" class="circle" />
            </div>
            :
            ''
            }
          </div>
          <div class="form-block w-form">
            <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" class="form" aria-label="registrazione" onSubmit={e => onSubmit(e)}>
              <h3 id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99db-240c86e7" class="no-margin">I tuoi dati</h3>
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="nome" class="form-label">Nome <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="nome" data-name="nome" placeholder="Nome" id="nome" required="true" value={nome} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="cognome" class="form-label">Cognome <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="cognome" data-name="cognome" placeholder="Cognome" id="cognome" required="true" value={cognome} onChange={e => onChange(e)} />
              </div>              
              <div id="w-node-_8f3a525e-f3a8-a69e-285d-23863f4bcca5-347debdf" class="column">
                <label for="codicefiscale" class="form-label">Codice Fiscale <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="codicefiscale" data-name="codicefiscale" placeholder="Codice fiscale" id="codicefiscale" required="true" value={codicefiscale} disabled />
              </div>  
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="email1" class="form-label">Indirizzo email <sup>1</sup><span class="required">*</span></label>
                <input type="email" class="input-field w-input" maxlength="256" name="email1" data-name="email1" placeholder="Email" id="email1" required="true" value={email1} onChange={e => onChange(e)} />
              </div> 
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="email2" class="form-label">Indirizzo email <sup>2</sup></label>
                <input type="email" class="input-field w-input" maxlength="256" name="email2" data-name="email2" placeholder="Email" id="email2" value={email2} onChange={e => onChange(e)} />
              </div>
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="luogoNascita" class="form-label">Luogo di nascita <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="luogoNascita" data-name="luogoNascita" placeholder="Luogo di nascita" required="true" id="luogoNascita" value={luogoNascita} onChange={e => onChange(e)} />
              </div> 
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="dataNascita" class="form-label">Data di nascita <span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="dataNascita" data-name="dataNascita" placeholder="Data di nascita" id="dataNascita" required="true" value={dataNascita} onChange={e => onChange(e)} />
              </div> 
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="telefono1" class="form-label">Numero telefono <sup>1</sup><span class="required">*</span></label>
                <input type="text" class="input-field w-input" maxlength="256" name="telefono1" data-name="telefono1" placeholder="Numero di telefono 1" id="telefono1" required="true" value={telefono1} onChange={e => onChange(e)} />
              </div> 
              <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                <label for="telefono2" class="form-label">Numero telefono <sup>2</sup></label>
                <input type="text" class="input-field w-input" maxlength="256" name="telefono2" data-name="telefono2" placeholder="Numero di telefono 2" id="telefono2" value={telefono2} onChange={e => onChange(e)} />
              </div> 
              <input type="submit" value="Salva" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button"/>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
};

ModificaProfilo.propTypes = {
  auth: PropTypes.object.isRequired,
  modificaProfiloCondomino: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { modificaProfiloCondomino })(ModificaProfilo);