import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkLinkRegistrazioneCondominio } from '../../actions/condominio';
import { registraNuovoCondomino } from '../../actions/auth';



const Registrazione = ({ alert, condominio: {condominio, loading}, checkLinkRegistrazioneCondominio, isAuthenticated, registraNuovoCondomino }) => {


    let { linkRegistrazione } = useParams();

    useEffect(() => {
        if(linkRegistrazione.length > 0){
            checkLinkRegistrazioneCondominio({linkRegistrazione: linkRegistrazione})
        }
      }, []);

      useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "error"){
                //setRedirect(true)
            }
        }
    }, [alert]);

    const [condominioData, setCondominiData] = useState({
        idCondominio: "",
        nomeCondominio: "",
        urlImmagine: ""
    });

  
    useEffect(() => {
      setCondominiData({
        idCondominio: loading || !condominio ? '' : condominio._id,
        nomeCondominio: loading || !condominio ? '' : condominio.nome,
        urlImmagine: loading || !condominio ? '' : condominio.urlImmagine,
      })
    }, [condominio, loading]);
    
    const { idCondominio, nomeCondominio, urlImmagine } = condominioData;

    const [registrazioneSuccess, setRegistrazioneSuccess] = useState(false)
    const [privacyCheck, setPrivacyCheck] = useState(false)

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "error" && alert[size-1].field === "redirect"){
                setRedirect(true)
            }
            if(alert[size-1].alertType === "success"){
                setUtente(initialStateCondomino)
                setRegistrazioneSuccess(true)
            }
        }
    }, [alert]);

    const [redirect, setRedirect] = useState(false)

    const initialStateCondomino = {
        active: false,
        username: '',
        password: '',
        nome: '',
        cognome: '',
        ruolo: '',
        codicefiscale: '',
        email1: '',
        email2: '',
        luogoNascita: '',
        dataNascita: '',
        telefono1: '',
        telefono2: '',
        urlDocumentiPrivati: '',
        categorie: []
    }
    const [utente, setUtente] = useState(initialStateCondomino);

    const { active, nome, cognome, password, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, urlDocumentiPrivati, categorie } = utente;

    const onChange = e => setUtente({ ...utente, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        registraNuovoCondomino({ active, idCondominio, nome, cognome, password, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, urlDocumentiPrivati, categorie })
    };

    if (isAuthenticated || redirect) {
        return <Redirect to="/" />
    }
    return (
        <>
            <div data-animation="default" data-collapse="none" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navigation w-nav">
                <div class="nav-container">
                    <a href="/" class="brand-header w-nav-brand"><img src="/assets/media/logo-amministrazioni2t.png" loading="lazy" alt="" class="logo-header" /></a>
                    <nav role="navigation" class="nav-menu w-nav-menu">
                        <div class="nav-menu-mask"></div>
                    </nav>
                    <div scroll="both" class="nav-menu-button w-nav-button">
                        <div class="nav-menu-button-wrap">
                            <div class="nav-lines nav-line-3"></div>
                            <div class="nav-lines nav-line-3"></div>
                            <div class="nav-lines nav-line-3"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="registrazione-condomino" class="section lightblue p-xl wf-section">
                <div class="container flex-center">
                    <div class="form-wrap reg">
                        <div class="center">
                            <h3 class="no-margin">{nomeCondominio}</h3>
                            <div class="div-block"><img src={urlImmagine ? urlImmagine : '/assets/media/favicon.png'} loading="lazy" class="circle" /></div>
                        </div>
                        <div class="form-block w-form">
                            {registrazioneSuccess ?
                            <div class="container flex-center">
                            <h3 class="no-margin">Congratulazioni!</h3>
                            <p class="m10 center">La tua registrazione è andata a buon fine, ora il nostro team dovrà accettare la tua richiesta affinché tu possa accedere all'area riservata.</p>
                            <p class="m10 center">Verrai avvisato via email quando la tua richiesta sarà confermata.</p>
                            </div>
                            :
                            <form id="wf-form-registrazione" name="wf-form-registrazione" data-name="registrazione" class="form" aria-label="registrazione" onSubmit={e => onSubmit(e)}>
                                <h3 id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99db-240c86e7" class="no-margin">I tuoi dati</h3>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="nome" class="form-label">Nome <span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="nome" data-name="nome" placeholder="Nome" id="nome" required="true" value={nome} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="cognome" class="form-label">Cognome <span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="cognome" data-name="cognome" placeholder="Cognome" id="cognome" required="true" value={cognome} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="codicefiscale" class="form-label">Codice Fiscale <span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="codicefiscale" data-name="codicefiscale" placeholder="Codice fiscale" id="codicefiscale" required="true" value={codicefiscale} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="password" class="form-label">Password <span class="required">*</span> <i style={{fontSize:"12px"}}>(min. 8 caratteri)</i></label>
                                    <input type="password" class="input-field w-input" maxlength="256" name="password" data-name="password" placeholder="Password" id="password" required="true" value={password} onChange={e => onChange(e)} minlength="8"/>
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="email1" class="form-label">Indirizzo email <sup>1</sup><span class="required">*</span></label>
                                    <input type="email" class="input-field w-input" maxlength="256" name="email1" data-name="email1" placeholder="Email" id="email1" value={email1} required="true" onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="email2" class="form-label">Indirizzo email <sup>2</sup></label>
                                    <input type="email" class="input-field w-input" maxlength="256" name="email2" data-name="email2" placeholder="Email" id="email2" value={email2} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="luogoNascita" class="form-label">Luogo di nascita <span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="luogoNascita" data-name="luogoNascita" placeholder="Luogo di nascita" required="true" id="luogoNascita" value={luogoNascita} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="dataNascita" class="form-label">Data di nascita <span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="dataNascita" data-name="dataNascita" placeholder="Data di nascita" required="true" id="dataNascita" value={dataNascita} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="telefono1" class="form-label">Numero telefono <sup>1</sup><span class="required">*</span></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="telefono1" data-name="telefono1" placeholder="Numero di telefono 1" required="true" id="telefono1" value={telefono1} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-_6aab59d1-f84d-b2bd-8cd4-f48b91ca99dd-240c86e7" class="column">
                                    <label for="telefono2" class="form-label">Numero telefono <sup>2</sup></label>
                                    <input type="text" class="input-field w-input" maxlength="256" name="telefono2" data-name="telefono2" placeholder="Numero di telefono 2" id="telefono2" value={telefono2} onChange={e => onChange(e)} />
                                </div>
                                <div id="w-node-a90294b5-464b-9bbb-81f2-33bae37026da-d15f29e1" class="column">
                                <a id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-5937f26f" class="item-grid w-inline-block">
                                    <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-5937f26f">
                                        <input type="checkbox" checked={privacyCheck} id="63e2796a51d4e5a3319eaff6" data-name="Pricing Plan" onChange={e => setPrivacyCheck(!privacyCheck)} required="true" /> <span class="required">*</span> L'utente dichiara di aver letto e ben compreso l’<a href="/informativa_privacy.pdf" target="_blank">Informativa sulla privacy e le disposizioni in materia di raccolta e trattamento delle informazioni</a> che possono avvenire durante l’utilizzo, registrazione e cancellazione dal portale.
                                    </div>
                                </a>
                                </div>
                                <input type="submit" value="Registrati" data-wait="Un attimo..." id="w-node-fcbab871-ad04-d61a-20e1-9b99f7049d2b-40413f47" class="submit-button w-button"/>
                            </form>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

Registrazione.propTypes = {
    checkLinkRegistrazioneCondominio: PropTypes.func.isRequired,
    registraNuovoCondomino: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
    condominio: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    alert: state.alert,
    condominio: state.condominio
});

export default connect(mapStateToProps, { checkLinkRegistrazioneCondominio, registraNuovoCondomino })(Registrazione);