import axios from 'axios';
import { REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, MODIFICA_PROFILO_CONDOMINO, REGISTRA_NUOVO_CONDOMINO, RECUPERA_PASSWORD, CHECK_TOKEN, MODIFICA_PASSWORD, PROFILO_ERROR } from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import history from '../History';

export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const res = await axios.get('/api/auth');
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: AUTH_ERROR
        });
    }
}

export const registraNuovoCondomino = ({ active, idCondominio, nome, cognome, password, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, categorie  }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ active, idCondominio, nome, cognome, password, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2, categorie  });
    try{
        const res = await axios.post('/api/auth/registraNuovoCondomino', body, config);
        dispatch({
            type: REGISTRA_NUOVO_CONDOMINO
        });
        dispatch(setAlert("Registrazione effettuata con successo!", 'success'))

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: REGISTER_FAIL
        });
    }
};



export const login = ({ codicefiscale, password}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ codicefiscale, password });
    try{
        const res = await axios.post('/api/auth', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());        
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

export const recuperaPassword = ({ codicefiscale}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ codicefiscale });
    try{
        const res = await axios.post('/api/auth/recuperaPassword', body, config);
        dispatch({
            type: RECUPERA_PASSWORD,
            payload: res.data
        });
        dispatch(setAlert("Richiesta inviata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: AUTH_ERROR
        });
    }
};

export const checkToken = ({ token }) => async dispatch => {
    try{
        const res = await axios.get(`/api/auth/checkToken/${token}`);
        dispatch({
            type: CHECK_TOKEN,
            payload: res.data
        });
        if(!res.data){
            dispatch(setAlert("", 'redirect'))
        }
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: AUTH_ERROR
        });
    }
};


export const modificaPassword = ({ token, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ token, password });
    try{
        const res = await axios.post('/api/auth/modificaPassword', body, config);
        dispatch({
            type: MODIFICA_PASSWORD,
            payload: res.data
        });
        dispatch(setAlert("Password modificata con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: PROFILO_ERROR
        });
    }
}


export const modificaProfiloCondomino = ({ idCondomino, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2 }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondomino, nome, cognome, codicefiscale, email1, email2, luogoNascita, dataNascita, telefono1, telefono2 });
    try{
        const res = await axios.post('/api/auth/modificaProfiloCondomino', body, config);
        dispatch({
            type: MODIFICA_PROFILO_CONDOMINO,
            payload: res.data
        });
        dispatch(setAlert("Profilo modificato con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: PROFILO_ERROR
        });
    }
}

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });  
    setAuthToken(null);
    history.push("/login");
};