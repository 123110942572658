import { GET_LOGO, GET_IMPOSTAZIONI, MODIFCA_IMPOSTAZIONI, IMPOSTAZIONI_ERROR } from '../actions/types';

const initialState = {
    logo: '',
    emailNotifiche: '',
    loading: true
}

export default function auth (state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_LOGO:
            return {
                ...state,
                loading: false,
                logo: payload
            }; 
        case GET_IMPOSTAZIONI:
        case MODIFCA_IMPOSTAZIONI:
            return {
                ...state,
                logo: payload.logo,
                emailNotifiche: payload.emailNotifiche
            }
        case IMPOSTAZIONI_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}