import { GET_LIST_DOCUMENTI, GET_LIST_DOCUMENTI_GENERALI, GET_LIST_DOCUMENTI_GESTIONI, GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA, GET_LIST_DOCUMENTI_DOCUMENTI, GET_LIST_DOCUMENTI_PRIVATI, GET_LINK_DOCUMENTO, CLEAR_LINK_DOCUMENTO, GET_CARTELLE_TUTTI_CONDOMINIO, GET_CARTELLE_AREA_PRIVATA_CONDOMINIO, DOCUMENTO_ERROR } from '../actions/types';

const initialState = {
    listDocumentiPubblici: [],
    listDocumentiGestioni: [],
    listDocumentiPrivati: [],
    listDocumentiVerbaliAssemblea: [],
    listDocumentiDocumenti: [],
    documento: {data: null, nome: null, link: null},
    loading: true
}

export default function auth (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_LIST_DOCUMENTI:
        case GET_LIST_DOCUMENTI_GENERALI:
        case GET_CARTELLE_TUTTI_CONDOMINIO:
            return {
                ...state,
                listDocumentiPubblici: payload,
                loading: false
            };
        case GET_CARTELLE_AREA_PRIVATA_CONDOMINIO:
        case GET_LIST_DOCUMENTI_PRIVATI:
            return {
                ...state,
                listDocumentiPrivati: payload,
                loading: false
            };
        case GET_LIST_DOCUMENTI_GESTIONI:
            return {
                ...state,
                listDocumentiGestioni: payload,
                loading: false
            };
        case GET_LIST_DOCUMENTI_VERBALI_ASSEMBLEA:
            return {
                ...state,
                listDocumentiVerbaliAssemblea: payload,
                loading: false
            };
        case GET_LIST_DOCUMENTI_DOCUMENTI:
            return {
                ...state,
                listDocumentiDocumenti: payload,
                loading: false
            };
        case GET_LINK_DOCUMENTO:
            return {
                ...state,
                documento: payload,
                loading: false
            };
        case CLEAR_LINK_DOCUMENTO:
            return {
                ...state,
                listDocumentiPubblici: [],
                listDocumentiPrivati: [],
                listDocumentiGestioni: [],
                listDocumentiVerbaliAssemblea: [],
                listDocumentiDocumenti: [],
                documento: {data: null, nome: null, link: null},
                loading: false
            };
        case DOCUMENTO_ERROR:
            return {
                ...state,
                listDocumentiPubblici: [],
                listDocumentiPrivati: [],
                listDocumentiGestioni: [],
                listDocumentiVerbaliAssemblea: [],
                listDocumentiDocumenti: [],
                documento: {data: null, nome: null, link: null},
                loading: false
            };
        default:
            return state;
    }
}