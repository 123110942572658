import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {apriFileFromBlob} from '../../utils/utility'
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import DocumentoElement from '../documenti/DocumentoElement';
import CartellaElement from '../documenti/CartellaElement';
import { getListDocumentiDocumenti, getLinkDocumento, clearlinkDocumento } from '../../actions/documento';

const DocumentiDocumenti = ({ auth: { user, loading }, documento: { listDocumentiDocumenti, documento }, getListDocumentiDocumenti, getLinkDocumento, clearlinkDocumento }) => {

  const isConsigliere = (user) => {
    let result = false
    if(user){
      if(user.categorie.includes("Consigliere")){
        result = true
      }
    }
    return result
  }

  const [utente, setUtente] = useState({
    nomeCartellaCondiminio: ''
  });


  const [pathFolder, setPathFolder] = useState("");
  const [elementiFile, setElementiFile] = useState([]);
  const [elementiFolder, setElementiFolder] = useState([]);
  const [currentPath, setCurrentPath] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState("");


  useEffect(() => {
    setUtente({
      idCondominio: loading || !user || !user.condominio ? '' : user.condominio._id
    })
    if(user && user.condominio && user.condominio){
      getListDocumentiDocumenti({ idCondominio: user.condominio._id, path: currentPath})
    }
    if(user){
      let isConsigliere = user.categorie.includes("Consigliere")
      setPathFolder(`02 - area privata web/documenti/${isConsigliere ? 'consiglieri' : 'tutti'}`)
    }
  }, [user, loading]);


  const { idCondominio } = utente;



  useEffect(() => {
    return () => {
      setElementiFolder([])
      setElementiFile([])
      clearlinkDocumento()
    };
  }, []);


  useEffect(() => {
    let arrayFolder = [];
    let arrayFile = [];
    if (listDocumentiDocumenti.length > 0) {
      let listFiletered = listDocumentiDocumenti.filter(el => el.name && !el.name.includes('+'))
      listFiletered.map(el => {
        if(el[".tag"] === "folder"){
          arrayFolder.push(el)
        }
        if(el[".tag"] === "file"){
          arrayFile.push(el)
        }
      })
    }
    arrayFolder = arrayFolder.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)); 
    arrayFile = arrayFile.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)); 
    setElementiFolder(arrayFolder)
    setElementiFile(arrayFile)
  }, [listDocumentiDocumenti]);

  useEffect(() => {
    apriFileFromBlob({documento: documento})
  }, [documento]);

  const onClickGetLinkDocumento = (path) => {
    getLinkDocumento({ path: path })
  }
  const onClickListFolder = (path) => {
    let str = path.substring(path.indexOf(pathFolder) + pathFolder.length);
    console.log(str) 
    setBreadcrumbs(breadcrumbs + generaBreadcrumbs(str))
    setCurrentPath(str)
    getListDocumentiDocumenti({ idCondominio: idCondominio, path: str })
  }

  const generaBreadcrumbs = (path) => {
    let index = path.lastIndexOf("-")
    let breadcrumb = "";
    if(index >= 0){
      breadcrumb = path.slice(path.lastIndexOf("-")+1);
    }else{
      index = path.lastIndexOf("/")
      if(index >= 0){
        breadcrumb = path.slice(path.lastIndexOf("/")+1);
      }
    }
    return '<span class="text-span-2"> > </span>' + breadcrumb;
  }

  const onClickBackListFolder = () => {
    let path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    setCurrentPath(path)
    setBreadcrumbs(path ? generaBreadcrumbs(path) : path)
    getListDocumentiDocumenti({ path: path })
  }
  

  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar 
        labelPagina={`Documenti ${breadcrumbs}`}
        isFolder={true}
        onClickBackListFolder={onClickBackListFolder}
     />
      <div class="w100">
        <div class="margin mt0">
          <div class="list-categorie">
          {elementiFolder.length > 0 ?
            elementiFolder.map(el => (
                <CartellaElement
                  onClickListFolder={onClickListFolder}
                  nomeCartella={el.name}
                  urlCartella={el.path_lower}
                />
            ))
          :
          <span></span>
          }
          </div>
        </div>
      </div>
      <div class="w100">
        <div class="margin mt0">
          <div class="colonne">
            <div class="w100">
            {elementiFile.length > 0 ?
                <div class="container-box zoom">
                  <div class="lista-file zoom">
                    {elementiFile.map(el => (
                      <DocumentoElement
                        onClickGetLinkDocumento={onClickGetLinkDocumento}
                        nomeFile={el.name}
                        urlFile={el.path_lower}
                        isPdf={el.name.endsWith(".pdf")}
                        isImg={el.name.endsWith(".png") || el.name.endsWith(".jpg") || el.name.endsWith(".jpeg")}
                        privato={false}
                      />
                    ))}
                  </div>
                </div>
                : elementiFolder.length > 0 ?
                  <span></span>
                  :
                  <p>Nessun documento trovato.</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

DocumentiDocumenti.propTypes = {
  auth: PropTypes.object.isRequired,
  documento: PropTypes.object.isRequired,
  getListDocumentiDocumenti: PropTypes.func.isRequired,
  getLinkDocumento: PropTypes.func.isRequired,
  clearlinkDocumento: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  documento: state.documento
});

export default connect(mapStateToProps, { getListDocumentiDocumenti, getLinkDocumento, clearlinkDocumento })(DocumentiDocumenti);