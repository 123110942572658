import React from 'react';

const Topbar = () => {

	return (
		<div class="footer wf-section">
    		<div class="container">
      			<div class="w100">
        			<p class="center footer footer-mobile">per assistenza: <a href="mailto:online@amministrazioni2t.com?subject=Assistenza%20Portale%20Condomini" class="link white">online@amministrazioni2t.com</a>  -  <a href="/changelog" class="link white">changelog</a> -  22am digital studio</p>
      			</div>
    		</div>
  		</div>
	)
}

export default (Topbar);

