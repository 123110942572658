import { GET_NOTIFICHE, CREA_NOTIFICA, GET_NOTIFICA_CONDOMINO, LEGGI_NOTIFICA, ELIMINA_NOTIFICA, NOTIFICA_ERROR } from '../actions/types';

const initialState = {
    notifiche: [],
    notifica: null,
    loading: true
}

export default function auth (state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_NOTIFICHE:
            return {
                ...state,
                notifiche: payload,
                loading: false
            };
        case CREA_NOTIFICA:
            let arrayNotifiche = [...state.notifiche];
            arrayNotifiche = arrayNotifiche.concat(payload);
            arrayNotifiche.sort((a,b) => (new Date(a.date) > new Date(b.date)) ? 1 : ((new Date(b.date) > new Date(a.date)) ? -1 : 0)); 
            return {
                ...state,
                notifiche: arrayNotifiche,
                loading: false
            };
        case ELIMINA_NOTIFICA:
            let arrayNotificheElimina = [...state.notifiche];
            arrayNotificheElimina = arrayNotificheElimina.filter(function (el) { return !payload.includes(el._id)});
            return {
                ...state,
                notifiche: arrayNotificheElimina,
                loading: false
            };
        case LEGGI_NOTIFICA:
            let arrayNotificheLeggi = [...state.notifiche];
            arrayNotificheLeggi = arrayNotificheLeggi.filter(function (el) { return el._id !== payload});
            return {
                ...state,
                notifiche: arrayNotificheLeggi,
                loading: false
            };
        case GET_NOTIFICA_CONDOMINO:
            return {
                ...state,
                notifica: payload,
                loading: false
            };
        case NOTIFICA_ERROR:
            return {
                ...state,
                notifiche: [],
                notifica: null,
                loading: false
            };
        default:
            return state;
    }
}