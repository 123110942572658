import axios from 'axios';
import { setAlert } from './alert';
import { GET_CONDOMINIO, GET_CONDOMINI, CREA_CONDOMINIO, ATTIVA_DISATTIVA_CONDOMINIO, MODIFICA_CONDOMINIO, CHECK_LINK_REGISTRAZIONE_CONDOMINIO, GET_CONDOMINI_CONSIGLIERI, CONDOMINIO_ERROR } from './types';


export const getCondominio= ({ idCondominio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio });
    try{
        const res = await axios.post('/api/condominio/getCondominio', body, config);
        dispatch({
            type: GET_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const getCondomini= () => async dispatch => {
    try{
        const res = await axios.get('/api/condominio/getCondomini');
        dispatch({
            type: GET_CONDOMINI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const creaCondominio= ({ nome, indirizzo, urlImmagine, linkRegistrazione, nomeCartella, nomeBanca, iban, numeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ nome, indirizzo, urlImmagine, linkRegistrazione, nomeCartella, nomeBanca, iban, numeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti });
    try{
        const res = await axios.post('/api/condominio/creaCondominio', body, config);
        dispatch({
            type: CREA_CONDOMINIO,
            payload: res.data
        });
        dispatch(setAlert("Condominio creato con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const attivaDisattivaCondominio= ({ idCondominio, attiva }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, attiva });
    try{
        const res = await axios.post('/api/condominio/attivaDisattivaCondominio', body, config);
        dispatch({
            type: ATTIVA_DISATTIVA_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const modificaCondominio= ({ idCondominio, nome, indirizzo, linkRegistrazione, nomeCartella, nomeBanca, iban, numeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti, urlImmagine }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio, nome, indirizzo, linkRegistrazione, nomeCartella, nomeBanca, iban, numeriUtili, codicefiscale, periodoGestione, foglioMappale, immagineFoglioMappa, noteCondominio, documenti, urlImmagine });
    try{
        const res = await axios.post('/api/condominio/modificaCondominio', body, config);
        dispatch({
            type: MODIFICA_CONDOMINIO,
            payload: res.data
        });
        dispatch(setAlert("Condominio modificato con successo!", 'success'))
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const checkLinkRegistrazioneCondominio = ({ linkRegistrazione }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ linkRegistrazione });
    try{
        const res = await axios.post('/api/condominio/checkLinkRegistrazioneCondominio', body, config);
        dispatch({
            type: CHECK_LINK_REGISTRAZIONE_CONDOMINIO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error', "redirect")));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}

export const getCondominiConsigliere = ({ idCondominio }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCondominio });
    try{
        const res = await axios.post('/api/condominio/getCondominiConsigliere', body, config);
        dispatch({
            type: GET_CONDOMINI_CONSIGLIERI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error', "redirect")));
        }
        dispatch({
            type: CONDOMINIO_ERROR
        });
    }
}
