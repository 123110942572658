import { REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, MODIFICA_PROFILO_CONDOMINO, PROFILO_ERROR } from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true,
    user: null
}

export default function auth (state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case USER_LOADED:
        case MODIFICA_PROFILO_CONDOMINO:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            };            
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            };
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                user: null,
                loading: false
            };
        case PROFILO_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}