import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import Topbar from '../layout/Topbar';
import QRCodeElement from '../layout/QRCodeElement';

import { getCondomini } from '../../actions/condominio';

const GestioneCondominio = ({ auth: { user, loading }, condominio: { condomini }, getCondomini }) => {

  const [condominiData, setCondominiData] = useState([]);
  const [testoCerca, setTestoCerca] = useState([]);

  
  useEffect(() => {
    getCondomini()
  }, []);

  useEffect(() => {
    setCondominiData(condomini)
  }, [condomini]);


  if (user && user.ruolo !== 'admin') {
    return <Redirect to="/" />
}

const onChangeFiltraCondominio = (testo) => {
  setTestoCerca(testo)
  let arrayCondominio = [...condomini]
  if(testo){
    arrayCondominio = arrayCondominio.filter(el => (el.nome.toLowerCase().includes(testo.toLowerCase())))
  }
  setCondominiData(arrayCondominio)
}



const onClickCopyText = (e, str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
};

const generaLinkGoogleMaps = (indirizzo) => {
  let link = "https://www.google.com/search?q="
  const array = indirizzo.split(' ')
  array.map((el, i) => {
    if(i === 0){
      link = link+el
    }else{
      link = link+"+"+el
    }
  })
  return link
}

const sostituisciSpaziLink = (link) => {
  return encodeURIComponent(link)
}


  return (loading ? <Spinner /> :
    <div class="section lightblue user-block wf-section">
      <Topbar
        labelPagina={"Gestione condominio"}
        isFolder={false}
      />
      <div class="w100">
        <div class="margin mt0">
          <div class="esterni gestione">
            <form action="/search" class="search w-form">
              <input type="search" class="search-input w-input" maxlength="256" name="query" placeholder="Cerca condominio" id="search-2" value={testoCerca} onChange={(e) => onChangeFiltraCondominio(e.target.value)} />
              <input disabled  type="submit" value="Cerca" class="search-btn w-button" />
              </form>
            <a href="/crea-condominio" class="button-small w-button">Crea nuovo condominio</a>
          </div>
          <div class="colonne">
            <div class="w100">
              <div class="list-gestione">
                <div class="wide-responsive">
                  <div class="w-layout-grid condominio-grid-indice">
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f47-f503dc95">
                      <p>Immagine</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4a-f503dc95">
                      <p>Nome condominio</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f4d-f503dc95">
                      <p>Indirizzo condominio</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-f503dc95">
                      <p>Link registrazione condomini</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-f503dc95">
                      <p>Documenti pubblici</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-f503dc95">
                    </div>
                  </div>
                  <div>
                  {condominiData.map((el) => (
                    <div class="w-layout-grid condominio-grid">
                      <a href={`modifica-condominio/${el._id}`}><img src={el.urlImmagine ? el.urlImmagine : '/assets/media/favicon.png'} loading="lazy" id="w-node-_809699ea-a821-6b35-767a-8b0f48ba1c0a-f503dc95" alt="" class="image-2" /></a>
                      <a href={`modifica-condominio/${el._id}`} id="w-node-aa79ef2e-e386-41a0-aedb-de658cd5f1b9-f503dc95" class="item-grid w-inline-block">
                        <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c511-f503dc95">
                          <p>{el.nome}</p>
                        </div>
                      </a>
                      <a href={generaLinkGoogleMaps(el.indirizzo)} target="_blank" id="w-node-_4998b544-e2a8-52f7-0b3f-4ed35686f90a-f503dc95" class="item-grid w-inline-block">
                      <div class="adiacenti">
                          <p>{el.indirizzo}</p>
                          <img src="assets/media/link.png" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-f503dc95" alt="" class="icon" />
                        </div>
                      </a>
                      <a id="w-node-_129abfbd-26fe-77c4-abed-96d65d34ab9e-f503dc95" class="item-grid w-inline-block" onClick={(e) => {onClickCopyText(e, `https://${window.location.hostname}/registrazione/${sostituisciSpaziLink(el.linkRegistrazione)}`)}}>
                        <div id="w-node-c1a569e8-7b12-3686-abff-0bfa7804c50f-f503dc95" class="adiacenti">
                          {el.linkRegistrazione ?
                          <>
                          <p>{sostituisciSpaziLink(el.linkRegistrazione)}</p>
                          <img src="assets/media/copy-regular.svg" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-f503dc95" alt="" class="icon" />
                          </>
                          : ''  
                          }
                        </div>
                      </a>
                      <a id="w-node-_4998b544-e2a8-52f7-0b3f-4ed35686f90a-f503dc95" href={`documenti-generali-admin/${el._id}`} class="item-grid w-inline-block">
                        <div class="adiacenti">
                          <p>vedi</p>
                          <img src="assets/media/link.png" loading="lazy" id="w-node-a876c42c-a290-8cf5-c375-8b2580240081-f503dc95" alt="" class="icon" />
                        </div>
                      </a>
                        <QRCodeElement data={sostituisciSpaziLink(el.linkRegistrazione)} />
                    </div>
                  ))}
                  </div>
                  <div class="w-layout-grid condominio-grid-indice">
                    <div id="w-node-ac45c48c-93fd-d8c5-d363-31e6e5b8b3bb-f503dc95">
                      <p>Immagine</p>
                    </div>
                    <div id="w-node-_85faeca0-47f6-1d2e-de37-35da2f2d57fa-f503dc95">
                      <p>Nome condominio</p>
                    </div>
                    <div id="w-node-_37c584d9-72bd-390d-838b-b14b16683a7b-f503dc95">
                      <p>Indirizzo condominio</p>
                    </div>
                    <div id="w-node-fbd904a1-392d-7d6a-1da3-323f0ffc4365-f503dc95">
                      <p>Link registrazione condomini</p>
                    </div>
                    <div id="w-node-_2fd5e7d5-4fc4-3674-b272-0bf7d9751f50-f503dc95">
                      <p>Documenti pubblici</p>
                    </div>
                    <div id="w-node-fbd904a1-392d-7d6a-1da3-323f0ffc4365-f503dc95">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

GestioneCondominio.propTypes = {
  auth: PropTypes.object.isRequired,
  condominio: PropTypes.object.isRequired,
  getCondomini: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  condominio: state.condominio
});

export default connect(mapStateToProps, { getCondomini })(GestioneCondominio);